export const ionicAudioOutputEnum = {
	UNKNOWN: 0,
	WIRED: 1,
	BLUETOOTH: 2,
	DEVICE: 3,
}

export const initialIonic = {
	pages: {
		practice: {
			filters: {
				open: false,
				presets: {
					open: false,
				},
				complexity: {
					open: false,
					custom: {
						open: false,
					},
				},
				timeSignature: {
					open: false,
				},
			},
			playalong: {
				open: false,
			},
		},
		metronome: {
			currentBeat: 0,
			gapClickTimings: [],
		},
	},
	audioOutput: ionicAudioOutputEnum.UNKNOWN,
}
