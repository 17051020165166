import { Preferences } from '@capacitor/preferences'

const setName = async (key, value, stringify = true) => {
	if (!key || !value) {
		return
	}

	await Preferences.set({
		key,
		value: stringify ? JSON.stringify(value) : value,
	})
}

const getName = async (key, parse = true) => {
	const { value } = await Preferences.get({ key })

	if (!value) {
		console.warn(`Capacitor Preferences. No value found for key: ${key}`)
		return
	}

	return parse ? JSON.parse(value) : value
}

const removeName = async (key) => {
	if (!key) {
		return
	}

	if ((await getName(key)) === undefined) {
		return
	}

	await Preferences.remove({ key })
}

export { setName, getName, removeName }
