import get from 'lodash/get'

import { PlatformEnum } from '../../utils/constants/enum/platform'
import { isLocalhost } from '../../utils/functions'
import {
	SET_THEME,
	SET_DONATION_PROMPT,
	SET_ANALYTICS_PROMPT,
	SET_SHOW_SETTINGS,
	SET_SHOW_FILTERS,
	TOGGLE_SHOW_SETTINGS,
	TOGGLE_SHOW_FILTERS,
	TOGGLE_ANALYTICS_PROMPT,
	TOGGLE_DONATION_PROMPT,
	SET_RENDERED_ARRAY,
	SET_SHOW_GROOVE_MODAL,
	SET_SHOW_SWING,
	SET_MODAL_HH_ARRAY,
	SET_SELECTED,
	SET_HIGHLIGHTED,
	SET_BAR_STYLE,
	SET_SAVEABLE,
	SET_LOADABLE,
	SET_SHOW_LOAD_MODAL,
	SET_SHOW_FEEDBACK_MODAL,
	SET_SHOW_HELPER_MODAL,
	SET_SHOW_SOUNDBANK_MODAL,
	SET_SHOW_ERROR,
	SET_ERROR_TEXT,
	SET_RHYTHM_NAME,
	SET_TIME_NAME,
	SET_UPDATE_PROMPT,
	SET_FEATURES_LIST,
	SET_MOBILE_TIME_SIGNATURE_CONTROLS,
	MANUAL_TOGGLE_SCREEN_LOCK,
	SET_SCREEN_LOCK,
	SET_LOGIN_OVERLAY,
	SET_LANGUAGE,
	SET_RENDERING_MODE,
	SET_TRANSLATION_SETTINGS,
	SET_TRANSLATION_MODE,
	SET_APPLICATION_TEXT,
	SET_SHOW_CLICK_MODAL,
	SET_CLICK_MODAL_HAS_OPENED,
	SET_MOBILE_CONTROLS_DOWN,
	SET_PLATFORM,
	SET_SCREEN_LOCK_OBJECT,
	SET_MOBILE_TIME_SIGNATURE_SEEN,
	SET_SHOW_RV_PERMUTATIONS_MODAL,
} from '../actionTypes'

import { initialRendering } from '../initialStates/rendering'

export default function rendering(state = initialRendering, action) {
	switch (action.type) {
		case SET_PLATFORM: {
			const { platform } = action.payload
			return {
				...state,
				platform,
			}
		}
		case SET_THEME: {
			const { theme } = action.payload
			return {
				...state,
				theme: theme,
			}
		}
		case SET_SHOW_FILTERS: {
			const { showFilters } = action.payload
			let ts = get(state, [`mobileControls`, `timeSignature`, `show`])
			if (showFilters && ts) {
				ts = false
			}
			return {
				...state,
				showFilters: showFilters,
				mobileControls: {
					...state.mobileControls,
					timeSignature: {
						...state.mobileControls.timeSignature,
						show: ts,
					},
				},
			}
		}
		case SET_SHOW_SETTINGS: {
			const { showSettings } = action.payload
			let ts = get(state, [`mobileControls`, `timeSignature`, `show`])
			if (showSettings && ts) {
				ts = false
			}
			return {
				...state,
				showSettings: showSettings,
				mobileControls: {
					...state.mobileControls,
					timeSignature: {
						...state.mobileControls.timeSignature,
						show: ts,
					},
				},
			}
		}
		case TOGGLE_SHOW_SETTINGS: {
			let ts = get(state, [`mobileControls`, `timeSignature`, `show`])
			if (!state.showSettings && ts) {
				ts = false
			}
			return {
				...state,
				showSettings: !state.showSettings,
				mobileControls: {
					...state.mobileControls,
					timeSignature: {
						...state.mobileControls.timeSignature,
						show: ts,
					},
				},
			}
		}
		case TOGGLE_SHOW_FILTERS: {
			let ts = get(state, [`mobileControls`, `timeSignature`, `show`])
			if (!state.showFilters && ts) {
				ts = false
			}
			return {
				...state,
				showFilters: !state.showFilters,
				mobileControls: {
					...state.mobileControls,
					timeSignature: {
						...state.mobileControls.timeSignature,
						show: ts,
					},
				},
			}
		}
		case SET_ANALYTICS_PROMPT: {
			const { analyticsPrompt } = action.payload
			return {
				...state,
				analyticsPrompt: analyticsPrompt,
			}
		}
		case SET_DONATION_PROMPT: {
			const { donationPrompt } = action.payload
			if (donationPrompt && (get(state, 'platform') !== PlatformEnum.WEB || isLocalhost())) {
				// Never ask for a donation in an app version or on localhost
				return {
					...state,
				}
			}
			return {
				...state,
				donationPrompt: donationPrompt,
			}
		}
		case TOGGLE_ANALYTICS_PROMPT: {
			return {
				...state,
				analyticsPrompt: !state.analyticsPrompt,
			}
		}
		case TOGGLE_DONATION_PROMPT: {
			return {
				...state,
				donationPrompt: !state.donationPrompt,
			}
		}
		case SET_RENDERED_ARRAY: {
			const { renderedArray } = action.payload
			return {
				...state,
				renderedArray: renderedArray,
			}
		}
		case SET_SHOW_GROOVE_MODAL: {
			const { showGrooveModal } = action.payload
			return {
				...state,
				showModals: {
					...state.showModals,
					groove: showGrooveModal,
				},
			}
		}
		case SET_SHOW_SWING: {
			const { showSwing } = action.payload
			return {
				...state,
				showSwing: showSwing,
			}
		}
		case SET_MODAL_HH_ARRAY: {
			const { modalHHArray } = action.payload
			return {
				...state,
				modalHHArray: modalHHArray,
			}
		}
		case SET_SELECTED: {
			const { selected } = action.payload
			return {
				...state,
				selected: selected,
			}
		}
		case SET_HIGHLIGHTED: {
			const { highlighted } = action.payload
			return {
				...state,
				highlighted: highlighted,
			}
		}
		case SET_BAR_STYLE: {
			const { barSettings } = action.payload
			return {
				...state,
				barSettings: barSettings,
			}
		}
		case SET_SAVEABLE: {
			const { saveable } = action.payload
			return {
				...state,
				saveable: saveable,
			}
		}
		case SET_LOADABLE: {
			const { loadable } = action.payload
			return {
				...state,
				loadable: loadable,
			}
		}
		case SET_SHOW_LOAD_MODAL: {
			const { showLoadModal } = action.payload
			return {
				...state,
				showModals: {
					...state.showModals,
					load: showLoadModal,
				},
			}
		}
		case SET_SHOW_FEEDBACK_MODAL: {
			const { showFeedbackModal } = action.payload
			return {
				...state,
				showModals: {
					...state.showModals,
					feedback: showFeedbackModal,
				},
			}
		}
		case SET_SHOW_HELPER_MODAL: {
			const { show, title, text, footer } = action.payload
			return {
				...state,
				showModals: {
					...state.showModals,
					helper: {
						show,
						title,
						text,
						footer,
					},
				},
			}
		}
		case SET_SHOW_ERROR: {
			const { showError } = action.payload
			return {
				...state,
				showError: showError,
			}
		}
		case SET_ERROR_TEXT: {
			const { errorText } = action.payload
			return {
				...state,
				errorText: errorText,
			}
		}
		case SET_RHYTHM_NAME: {
			const { name, preset } = action.payload
			return {
				...state,
				rhythmName: {
					...state.rhythmName,
					name,
					preset,
				},
			}
		}
		case SET_TIME_NAME: {
			const { timeName } = action.payload
			return {
				...state,
				timeName: timeName,
			}
		}
		case SET_SHOW_SOUNDBANK_MODAL: {
			const { showSoundbankModal } = action.payload
			return {
				...state,
				showSoundbankModal: showSoundbankModal,
			}
		}
		case SET_UPDATE_PROMPT: {
			const { updatePrompt } = action.payload
			return {
				...state,
				updatePrompt: updatePrompt,
			}
		}
		case SET_FEATURES_LIST: {
			const { featuresList } = action.payload
			return {
				...state,
				featuresList: featuresList,
			}
		}
		case SET_MOBILE_TIME_SIGNATURE_CONTROLS: {
			const { mobileTimeSignatureControls } = action.payload
			return {
				...state,
				mobileControls: {
					...state.mobileControls,
					timeSignature: {
						...state.mobileControls.timeSignature,
						show: mobileTimeSignatureControls,
					},
				},
			}
		}
		case SET_MOBILE_TIME_SIGNATURE_SEEN: {
			const { seen } = action.payload
			return {
				...state,
				mobileControls: {
					...state.mobileControls,
					timeSignature: {
						...state.mobileControls.timeSignature,
						seen,
					},
				},
			}
		}
		case SET_MOBILE_CONTROLS_DOWN: {
			const { down, manual, manualReset } = action.payload
			let isManual = manual || state.mobileControls.down.manual
			if (manualReset) {
				isManual = initialRendering.mobileControls.down.manual
			}

			return {
				...state,
				mobileControls: {
					...state.mobileControls,
					down: {
						value: down,
						manual: isManual,
					},
				},
			}
		}
		case MANUAL_TOGGLE_SCREEN_LOCK: {
			const screenLock = !state.screenLock.state
			return {
				...state,
				screenLock: {
					...state.screenLock,
					state: screenLock,
					forced: screenLock,
				},
			}
		}
		case SET_SCREEN_LOCK: {
			const { screenLock } = action.payload
			return {
				...state,
				screenLock: {
					...state.screenLock,
					state: screenLock,
				},
			}
		}
		case SET_SCREEN_LOCK_OBJECT: {
			const { lockObject } = action.payload
			return {
				...state,
				screenLock: {
					...state.screenLock,
					lockObject,
				},
			}
		}
		case SET_LOGIN_OVERLAY: {
			const { loginOverlay } = action.payload
			return {
				...state,
				loginOverlay,
			}
		}
		case SET_LANGUAGE: {
			const { language } = action.payload
			return {
				...state,
				translation: {
					...state.translation,
					language,
				},
			}
		}
		case SET_TRANSLATION_SETTINGS: {
			const { showSettings } = action.payload
			return {
				...state,
				translation: {
					...state.translation,
					showSettings,
				},
			}
		}
		case SET_TRANSLATION_MODE: {
			const { mode } = action.payload
			return {
				...state,
				translation: {
					...state.translation,
					mode,
				},
			}
		}
		case SET_APPLICATION_TEXT: {
			const { filters, canvas, credits } = action.payload
			return {
				...state,
				text: {
					...state.text,
					filters,
					canvas,
					credits,
				},
			}
		}
		case SET_RENDERING_MODE: {
			const { renderingMode } = action.payload
			return {
				...state,
				mode: renderingMode,
			}
		}
		case SET_SHOW_CLICK_MODAL: {
			const { showClickModal } = action.payload
			return {
				...state,
				showModals: {
					...state.showModals,
					click: {
						open: showClickModal,
						hasOpened: showClickModal || state.showModals.click.hasOpened,
					},
				},
			}
		}
		case SET_CLICK_MODAL_HAS_OPENED: {
			return {
				...state,
				showModals: {
					...state.showModals,
					click: {
						...state.showModals.click,
						hasOpened: true,
					},
				},
			}
		}

		case SET_SHOW_RV_PERMUTATIONS_MODAL: {
			const { show } = action.payload
			return {
				...state,
				showModals: {
					...state.showModals,
					rhythmicVocabularyPermutations: show,
				},
			}
		}

		default:
			return state
	}
}
