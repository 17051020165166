import React from 'react'
import { Provider } from 'react-redux'
import store from './redux/store'
import { createRoot } from 'react-dom/client'
import 'cordova-plugin-purchase'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'

import { setFeaturesList, setUpdatePrompt } from './redux/actions'
import { ionSetAudioOutput } from './redux/actions/ionicActions'
import { ionicAudioOutputEnum } from './redux/initialStates/ionic'
import { setIsPro, setProductId } from './redux/actions/inAppPurchasesActions'
import { _RbDebug } from './redux/actions/debuggerActions'

import { FeaturesListEnum } from './utils/constants/enum/featuresList'
import { beta, errorOccurred, isIonic, isLocalhost, isValidUrl, prod } from './utils/functions'
import './utils/deployment'
import { IAPKeys, PreferenceKeys as ProPreferenceKeys } from './utils/pro'
import { isDebug } from './utils/ionic/debug'
import { setName as capacitorPreferencesSet, getName as capacitorPreferencesGet } from './utils/ionic/preferences'
import { Network } from '@capacitor/network'
import { FeedbackCallTypes, feedbackApiUrl } from './utils/api/feedback'
import { iapValidationUrl } from './utils/api/iapValidation'
import { authToken } from './utils/api/auth'

const startRhythmBot = async () => {
	const container = document.getElementById('root')
	const root = createRoot(container)

	let Routes
	if (isIonic()) {
		Routes = (await import('./routes/Ionic/index.js')).Routes
	} else {
		Routes = (await import('./routes/Web.js')).Routes
	}

	root.render(
		<Provider store={store}>
			<Routes />
		</Provider>
	)

	serviceWorkerRegistration.register({
		onUpdate: (registration) => {
			if (registration && registration.waiting) {
				registration.waiting.postMessage({ type: 'SKIP_WAITING' })
			}

			store.dispatch(setUpdatePrompt(true))

			store.dispatch(setFeaturesList(FeaturesListEnum.UPDATE))
			localStorage.setItem('featuresList', FeaturesListEnum.UPDATE)
		},
	})
}

const checkAndUpdateAudioOutput = () => {
	if (!window?.cordova?.plugins?.audioroute?.currentOutputs) {
		// This plugin is not supported on Android
		return
	}

	window.cordova.plugins.audioroute.currentOutputs(
		(outputs) => {
			const output = outputs[0]
			let value = ionicAudioOutputEnum.DEVICE
			if (['headphones', 'line-out'].includes(output)) {
				value = ionicAudioOutputEnum.HEADPHONES
			} else if (['bluetooth-a2dp', 'bluetooth-le'].includes(output)) {
				value = ionicAudioOutputEnum.BLUETOOTH
			} else if (output === 'unknown') {
				value = ionicAudioOutputEnum.UNKNOWN
			}

			store.dispatch(ionSetAudioOutput(value))
		},
		(error) => {
			console.error('Error getting current outputs: ', error)
		}
	)
}

const IAPEvents = (cdvStore, id, isTest = false) => {
	if (!cdvStore) {
		return
	}
	if (!id) {
		return
	}

	// TODO show overall app loader when this is running?
	cdvStore
		.when(id)
		.approved((transaction) => {
			// plugin: api/classes/CdvPurchase.Transaction.md
			if (isDebug) {
				store.dispatch(_RbDebug('Approved', JSON.stringify(transaction)))
			}

			console.log(['CDV IAPEvents'], 'Approved', transaction)
			transaction.verify()
		})
		.verified(async (receipt) => {
			// plugin: api/classes/CdvPurchase.VerifiedReceipt.md
			console.log(['CDV IAPEvents'], 'Verified', receipt)
			if (isDebug) {
				store.dispatch(_RbDebug('Verified', JSON.stringify(receipt)))
			}

			if (!beta()) {
				// TODO remove this when we are ready to go live
				errorOccurred('Attempted to verify receipt in production')
			}

			const hasBeenPro = await capacitorPreferencesGet(ProPreferenceKeys.IONIC_PRO_HISTORY)

			if (!hasBeenPro) {
				await capacitorPreferencesSet(ProPreferenceKeys.IONIC_RECEIPT_VERIFYING, true)

				const isOnline = (await Network.getStatus()).connected
				if (!isOnline) {
					errorOccurred('CDV IAPEvents: could not verify receipt because the device is offline')
					await capacitorPreferencesSet(ProPreferenceKeys.IONIC_RECEIPT_VERIFYING, false)
					return
				}

				if (!isValidUrl(iapValidationUrl)) {
					errorOccurred(`CDV IAPEvents: Invalid validation API URL defined ${iapValidationUrl}`)
					await capacitorPreferencesSet(ProPreferenceKeys.IONIC_RECEIPT_VERIFYING, false)
					return
				}

				if (authToken === undefined || typeof authToken !== 'string') {
					errorOccurred(`CDV IAPEvents: invalid auth token defined for validation API ${typeof authToken === 'string' ? authToken : ''}`)
					await capacitorPreferencesSet(ProPreferenceKeys.IONIC_RECEIPT_VERIFYING, false)
					return
				}

				let platform
				if (isTest) {
					// Pick the test platform
					platform = 'ios-appstore'
				} else {
					platform = cdvStore.defaultPlatform()
				}

				try {
					const userId = cdvStore.getApplicationUsername()

					let headers = new Headers()
					headers.append('Content-Type', 'application/json')
					headers.append('Authorization', `${authToken}`)

					const validationResponse = await fetch(iapValidationUrl, {
						method: 'POST',
						headers,
						body: JSON.stringify({
							platform,
							receipt,
							userId,
							isProduction: prod({ isApp: true }),
						}),
					})

					const responseText = await validationResponse.text()
					const repsonseJson = JSON.parse(responseText)
					const statusCode = repsonseJson?.statusCode ?? 400

					if (statusCode !== 200) {
						errorOccurred(`CDV IAPEvents: Error validating receipt ${responseText}`)
						console.error(['CDV IAPEvents error'], responseText)

						// TODO how bulletproof does this need to be? have we
						// taken money at this point?
						// If so maybe we should just send ourselves an email
						// with the receipt and user ID? And still enable pro?

						const feebackHeaders = new Headers()
						feebackHeaders.append('Content-Type', 'application/json')
						// TODO add auth to this
						// feebackHeaders.append('Authorization', `${authToken}`)

						await fetch(feedbackApiUrl, {
							method: 'POST',
							headers: feebackHeaders,
							body: JSON.stringify({
								type: FeedbackCallTypes.IAP_VALIDATION_ERROR,
								userId,
								error: responseText,
								isProduction: prod({ isApp: true }),
							}),
						})
					} else {
						console.log(['CDV IAPEvents'], responseText)
					}
				} catch (error) {
					errorOccurred(['CDV IAPEvents'])
					console.error(['CDV IAPEvents error'], error)
					return
				} finally {
					await capacitorPreferencesSet(ProPreferenceKeys.IONIC_RECEIPT_VERIFYING, false)
				}
			}

			store.dispatch(setIsPro(true))
			receipt.finish()
		})
}

const initialiseIAP = (useTestPlatforms = false) => {
	const { CdvPurchase } = window
	const { store: cdvStore, ProductType, Platform, LogLevel } = CdvPurchase
	cdvStore.verbosity = LogLevel.DEBUG

	if (useTestPlatforms) {
		// For testing locally
		cdvStore.applicationUsername = 'test'
		const testID = 'test-non-consumable'
		store.dispatch(setProductId(testID))

		cdvStore.register([
			{
				id: testID,
				type: ProductType.NON_CONSUMABLE,
				platform: Platform.TEST,
			},
		])

		IAPEvents(cdvStore, testID, true)

		cdvStore.initialize([Platform.TEST])
		cdvStore.restorePurchases()
	} else {
		store.dispatch(setProductId(IAPKeys.pro))
		const defaultPlatform = cdvStore.defaultPlatform()
		// If there is no default platform, we need to set up all platforms
		const setManualPlatforms = !defaultPlatform

		if (setManualPlatforms) {
			// TODO is this necessary? Set up all platforms if the platform
			// cannot be found from the plugin?
			cdvStore.register([
				{
					id: IAPKeys.pro,
					type: ProductType.NON_CONSUMABLE,
					platform: Platform.APPLE_APPSTORE,
				},
				{
					id: IAPKeys.pro,
					type: ProductType.NON_CONSUMABLE,
					platform: Platform.GOOGLE_PLAY,
				},
			])

			IAPEvents(cdvStore, IAPKeys.pro)
			cdvStore.initialize([Platform.APPLE_APPSTORE, Platform.GOOGLE_PLAY])
		} else {
			cdvStore.register([
				{
					id: IAPKeys.pro,
					type: ProductType.NON_CONSUMABLE,
					platform: defaultPlatform,
				},
			])

			IAPEvents(cdvStore, IAPKeys.pro)
			cdvStore.initialize()
		}

		cdvStore.restorePurchases()
	}
}

if (!window.cordova) {
	startRhythmBot()

	if (isLocalhost()) {
		// For testing locally
		initialiseIAP(true)
	}
} else {
	console.log('Cordova is available')
	document.addEventListener(
		'deviceready',
		() => {
			startRhythmBot()

			initialiseIAP(false)

			checkAndUpdateAudioOutput()
			document.addEventListener('audioroute-changed', () => {
				checkAndUpdateAudioOutput()
			})
		},
		false
	)
}
