import { initialPlayback } from '../../redux/initialStates/playback'

export const bpmLimits = {
	min: 10,
	max: 300,
}

export const getValidBpm = (bpm) => {
	if (Number.isNaN(bpm)) {
		return initialPlayback.bpm
	}
	if (bpm < bpmLimits.min) {
		return bpmLimits.min
	}
	if (bpm > bpmLimits.max) {
		return bpmLimits.max
	}
	return bpm
}
