export const ModeEnum = {
	ONCE: 16,
	LOOP: 0,
	LOOPREADING: 2,
	TRADE: 1,
	TRADEREADING: 3,
	CLICK: 4,
	CLICKREADING: 5,
	IONICMETRONOME: 6,
}

export default ModeEnum
