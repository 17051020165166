import { TimeEnum } from './enum/time'

//Valid values for groove elements in each subdivision
const GrooveOptions = {
	options: [
		{
			id: TimeEnum.STRAIGHT,
			hh: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
		},
		{
			id: TimeEnum.EIGHTH,
			hh: [0, 1, 2, 3, 4, 5, 6, 7, 8, 11, 12],
		},
		{
			id: TimeEnum.TRIPLETS,
			hh: [0, 13, 14, 15, 11, 16],
		},
		{
			id: TimeEnum.MIXED,
			hh: [0, 1, 2, 3, 4, 11],
		},
	],
}
export default GrooveOptions
