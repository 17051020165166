import {
	ION_SET_PLAYALONG_OPEN,
	ION_SET_FILTERS_OPEN,
	ION_SET_PRESETS_OPEN,
	ION_SET_COMPLEXITY_OPEN,
	ION_SET_TIMESIGNATURE_OPEN,
	ION_SET_COMPLEXITY_CUSTOM_OPEN,
	ION_SET_AUDIO_OUTPUT,
	ION_METRONOME_SET_CURRENT_BEAT,
	ION_METRONOME_SET_GAP_CLICK_TIMINGS,
} from '../actionTypes'

export const ionSetFiltersOpen = (open) => ({
	type: ION_SET_FILTERS_OPEN,
	payload: {
		open,
	},
})

export const ionSetPresetsOpen = (open) => ({
	type: ION_SET_PRESETS_OPEN,
	payload: {
		open,
	},
})

export const ionSetComplexityOpen = (open) => ({
	type: ION_SET_COMPLEXITY_OPEN,
	payload: {
		open,
	},
})

export const ionSetComplexityCustomOpen = (open) => ({
	type: ION_SET_COMPLEXITY_CUSTOM_OPEN,
	payload: {
		open,
	},
})

export const ionSetTimeSignatureOpen = (open) => ({
	type: ION_SET_TIMESIGNATURE_OPEN,
	payload: {
		open,
	},
})

export const ionSetPlayalongSummaryOpen = (open) => ({
	type: ION_SET_PLAYALONG_OPEN,
	payload: {
		open,
	},
})

export const ionSetAudioOutput = (value) => ({
	type: ION_SET_AUDIO_OUTPUT,
	payload: {
		value,
	},
})

export const ionSetMetronomeCurrentBeat = (value) => ({
	type: ION_METRONOME_SET_CURRENT_BEAT,
	payload: {
		value,
	},
})

export const ionSetMetronomeGapClickTimings = (value, add = false) => ({
	type: ION_METRONOME_SET_GAP_CLICK_TIMINGS,
	payload: {
		value,
		add,
	},
})
