// Rendering mode is used to handle special notation rendering
// Normally RB will display musical notation however through URL query params it
// can be loaded in special modes e.g. down/up notation
export const RenderingModes = {
	NORMAL: 0,
	DOWNUP: {
		STRAIGHT: 1,
		TRIPLETS: 2,
	},
	SD: {
		TRIPLETS: {
			ONE: 3,
			TWO: 4,
			THREE: 5,
			FOUR: 6,
			FIVE: 7,
		},
	},
	FIRSTGROOVES: {
		ONE: 8,
		TWO: 9,
		THREE: 10,
		ALL: 11,
	},
	RHYTHMIC_VOCABULARY_PERMUTATIONS: {
		STRAIGHT: 12,
	},
}

export default RenderingModes
