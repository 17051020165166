import { SoundsEnum, CymbalEnum } from '../../utils/constants/enum/sounds'
import Modes from '../../utils/constants/enum/mode'
import Time from '../../utils/constants/enum/time'
import ClickRate from '../../utils/constants/enum/click'
import StepWiseModeEnum from '../../utils/constants/enum/click/stepwise'
import { BeatStateEnum } from '../../utils/constants/enum/ionic/metronome'
import { BackbeatPatterns, CymbalPatterns } from '../../utils/constants/enum/groove'

export const getInitialBeatVolumes = (numberOfBeats) => {
	return Array.from({ length: numberOfBeats }, (__, index) => {
		return { volume: 1.0, state: index === 0 ? BeatStateEnum.ACCENT : BeatStateEnum.ON }
	})
}

export const initialPlayback = {
	bars: 2,
	time: {
		options: Time.STRAIGHT,
		highestNoteRate: Time.STRAIGHT,
	},
	playbackTime: {
		options: null,
		highestNoteRate: null,
	},
	timeSignature: {
		top: 4,
		bottom: 4,
	},
	preset: {
		on: false,
		constants: {},
		shuffleAll: {
			on: false,
			params: {},
		},
	},
	random: false,
	playThis: [],
	bpm: 85,
	mode: Modes.LOOP,
	playing: false,
	click: {
		volume: 0.85,
		on: true,
		countIn: true,
		rate: {
			custom: false,
			selectedImage: 1,
			amount: 3,
			time: ClickRate.SIXTEENTH,
			markOne: false,
		},
		offset: {
			amount: 0,
			rate: ClickRate.SIXTEENTH,
			modified: false,
		},
		gap: {
			on: false,
			off: false,
			matchRhythmLength: false,
			startOff: false,
			controlsTouched: false,
		},
		ionic: {
			clicksPerBeat: 1,
			numberOfBeats: 4,
			rateDivider: 1, // Used for odd time rhythmic playback that is not compatible with the core click settings
			beatVolumes: getInitialBeatVolumes(4),
			stepwise: {
				mode: StepWiseModeEnum.INCREASE,
				bpm: 0,
				bars: 0,
			},
			disabled: {
				numberOfBeats: false,
				clicksPerBeat: false,
				tempoWheel: false,
				beatVolumes: false,
			},
		},
	},
	useGhosts: true,
	playbackAs: SoundsEnum.SNARE,
	groove: {
		cymbal: {
			pattern: CymbalPatterns.EIGHTH,
			sound: CymbalEnum.HH,
		},
		backbeat: BackbeatPatterns.TWO_AND_FOUR,
		mix: 0,
		lock: true,
	},
	swing: 0,
	custom: false,
	customArray: [],
	space: 0,
	selectedBars: [],
	isolated: false,
	playEnabled: true,
	isolatedArray: false,
	level: 5,
	levelsArray: false,
	tradeDirection: false,
	rhythmLock: false,
	playalong: {
		on: false,
		hideAccuracy: false,
		user: {
			hits: [],
			misses: [],
			accuracy: 0,
			history: [],
			rhythm: [],
			bars: 1,
			isSixteenth: true,
		},
	},
	rhythmicVocabularyPermutations: {
		groupingIndex: 0,
		startingPoints: [[0]], // Indexes within each bar that the grouping should start
	},
}
