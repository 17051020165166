import get from 'lodash/get'

import {
	ION_METRONOME_SET_CURRENT_BEAT,
	ION_METRONOME_SET_GAP_CLICK_TIMINGS,
	ION_SET_AUDIO_OUTPUT,
	ION_SET_COMPLEXITY_CUSTOM_OPEN,
	ION_SET_COMPLEXITY_OPEN,
	ION_SET_FILTERS_OPEN,
	ION_SET_PLAYALONG_OPEN,
	ION_SET_PRESETS_OPEN,
	ION_SET_TIMESIGNATURE_OPEN,
} from '../actionTypes'
import { initialIonic } from '../initialStates/ionic'

export default function ionic(state = initialIonic, action) {
	switch (action.type) {
		case ION_SET_FILTERS_OPEN: {
			const { open } = action.payload

			if (!open) {
				return {
					...state,
					pages: {
						...state.pages,
						practice: {
							...state.pages.practice,
							filters: {
								...get(initialIonic, [`pages`, `practice`, `filters`]),
							},
						},
					},
				}
			}
			return {
				...state,
				pages: {
					...state.pages,
					practice: {
						...state.pages.practice,
						filters: {
							...state.pages.practice.filters,
							open,
						},
					},
				},
			}
		}
		case ION_SET_PRESETS_OPEN: {
			const { open } = action.payload
			return {
				...state,
				pages: {
					...state.pages,
					practice: {
						...state.pages.practice,
						filters: {
							...state.pages.practice.filters,
							timeSignature: {
								open: open ? false : get(state, [`pages`, `practice`, `filters`, `timeSignature`, `open`]),
							},
							complexity: {
								...state.pages.practice.complexity,
								open: open ? false : get(state, [`pages`, `practice`, `filters`, `complexity`, `open`]),
							},
							presets: {
								open,
							},
						},
					},
				},
			}
		}
		case ION_SET_COMPLEXITY_OPEN: {
			const { open } = action.payload
			return {
				...state,
				pages: {
					...state.pages,
					practice: {
						...state.pages.practice,
						filters: {
							...state.pages.practice.filters,
							timeSignature: {
								open: open ? false : get(state, [`pages`, `practice`, `filters`, `timeSignature`, `open`]),
							},
							presets: {
								open: open ? false : get(state, [`pages`, `practice`, `filters`, `presets`, `open`]),
							},
							complexity: {
								...state.pages.practice.complexity,
								open,
							},
						},
					},
				},
			}
		}
		case ION_SET_COMPLEXITY_CUSTOM_OPEN: {
			const { open } = action.payload
			return {
				...state,
				pages: {
					...state.pages,
					practice: {
						...state.pages.practice,
						filters: {
							...state.pages.practice.filters,
							timeSignature: {
								open: open ? false : get(state, [`pages`, `practice`, `filters`, `timeSignature`, `open`]),
							},
							presets: {
								open: open ? false : get(state, [`pages`, `practice`, `filters`, `presets`, `open`]),
							},
							complexity: {
								...state.pages.practice.complexity,
								open: true,
								custom: {
									open,
								},
							},
						},
					},
				},
			}
		}
		case ION_SET_TIMESIGNATURE_OPEN: {
			const { open } = action.payload
			return {
				...state,
				pages: {
					...state.pages,
					practice: {
						...state.pages.practice,
						filters: {
							...state.pages.practice.filters,
							presets: {
								open: open ? false : get(state, [`pages`, `practice`, `filters`, `presets`, `open`]),
							},
							complexity: {
								...state.pages.practice.complexity,
								open: open ? false : get(state, [`pages`, `practice`, `filters`, `complexity`, `open`]),
							},
							timeSignature: {
								open,
							},
						},
					},
				},
			}
		}

		case ION_SET_PLAYALONG_OPEN: {
			const { open } = action.payload

			return {
				...state,
				pages: {
					...state.pages,
					practice: {
						...state.pages.practice,
						playalong: {
							open,
						},
					},
				},
			}
		}

		case ION_SET_AUDIO_OUTPUT: {
			const { value } = action.payload

			return {
				...state,
				audioOutput: value,
			}
		}

		case ION_METRONOME_SET_CURRENT_BEAT: {
			const { value } = action.payload

			return {
				...state,
				pages: {
					...state.pages,
					metronome: {
						...state.pages.metronome,
						currentBeat: value,
					},
				},
			}
		}

		case ION_METRONOME_SET_GAP_CLICK_TIMINGS: {
			const { value, add } = action.payload

			return {
				...state,
				pages: {
					...state.pages,
					metronome: {
						...state.pages.metronome,
						gapClickTimings: add ? [...state.pages.metronome.gapClickTimings, value] : value,
					},
				},
			}
		}
		default:
			return state
	}
}
