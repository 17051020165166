import get from 'lodash/get'
import toLower from 'lodash/toLower'

import RenderingModes from '../../utils/constants/enum/renderingMode'
import GrooveOptions from '../../utils/constants/groove-elements'
import { FeaturesListEnum } from '../../utils/constants/enum/featuresList'
import { PlatformEnum } from '../../utils/constants/enum/platform'
import { ThemeEnum } from '../../utils/constants/enum/theme'
import { Capacitor } from '@capacitor/core'

import { initialText } from './text'

const initialisePlatform = () => {
	const cordovaDevice = get(window, 'device', false)
	const capacitorDevice = Capacitor.isNativePlatform()
	if (!cordovaDevice && !capacitorDevice) {
		return PlatformEnum.WEB
	}
	if (capacitorDevice) {
		switch (Capacitor.getPlatform()) {
			case 'ios':
				return PlatformEnum.IOS
			case 'android':
				return PlatformEnum.ANDROID
			default:
				return
		}
	}
	switch (toLower(get(cordovaDevice, 'platform'))) {
		case 'ios':
			return PlatformEnum.IOS
		case 'android':
			return PlatformEnum.ANDROID
		default:
			return
	}
}

export const initialRendering = {
	platform: initialisePlatform(),
	text: {
		filters: initialText.filters,
		canvas: initialText.canvas,
		credits: initialText.credits,
	},
	mode: RenderingModes.NORMAL,
	theme: ThemeEnum.LIGHT,
	showSettings: false,
	showFilters: false,
	analyticsPrompt: false,
	donationPrompt: false,
	renderedArray: false,
	loginOverlay: false,
	signUpOverlay: false,
	showSwing: true,
	modalHHArray: GrooveOptions.options[0].hh,
	rhythmName: {
		name: false,
		preset: false,
	},
	timeName: '16th Note',
	selected: false,
	highlighted: 0,
	barSettings: {
		path: 'straight',
		style: 'straight',
	},
	saveable: [true, 'Save'],
	loadable: false,
	showModals: {
		groove: false,
		load: false,
		feedback: false,
		click: {
			show: false,
			hasOpened: false,
		},
		helper: {
			show: false,
			title: ``,
			text: ``,
			footer: null,
		},
		rhythmicVocabularyPermutations: false,
	},
	showError: false,
	errorText: '',
	showSoundbankModal: false,
	updatePrompt: false,
	featuresList: FeaturesListEnum.OFF,
	mobileControls: {
		timeSignature: {
			show: false,
			seen: false,
		},
		down: {
			value: false,
			manual: false,
		},
	},
	screenLock: {
		lockObject: null,
		state: false,
		forced: false,
	},
}
