export const initialText = {
	filters: {
		mode: {
			title: `Mode`,
			once: `Once`,
			standard: `Standard`,
			reading: `Reading`,
			loop: `Loop`,
			trade: {
				title: `Trade`,
				standard: {
					desktop: {
						you: `You first, click to switch order`,
						rb: `RhythmBot first, click to switch order`,
					},
					mobile: {
						you: `You first, tap to switch order`,
						rb: `RhythmBot first, tap to switch order`,
					},
				},
				reading: {
					you: `You first, infinite new rhythms`,
					rb: `RhythmBot first, infinite new rhythms`,
				},
			},
			click: {
				title: `Click`,
				subtitle: `Looping Metronome`,
			},
			infinite: `Infinite new rhythms`,
		},
		subdivision: {
			title: `Subdivision`,
			straight: `Straight`,
			triplets: `Triplets`,
			mixed: `Mixed`,
			sixteenth: `16th`,
			eighth: `8th`,
		},
		playback: {
			title: `Playback`,
			snare: `Snare`,
			kick: `Kick`,
			groove: {
				title: `Groove`,
				subtitle: {
					desktop: {
						full: `Click to edit cymbal, backbeat & swing`,
						short: `Click to edit cymbal & backbeat`,
					},
					mobile: {
						full: `Tap to edit cymbal, backbeat & swing`,
						short: `Tap to edit cymbal & backbeat`,
					},
				},
			},
		},
		bars: `Bars`,
		clear: `Clear`,
		random: `Random Rhythm`,
		presets: {
			title: `Presets`,
			subtitle: {
				sixteenth: `From JPBouvetMethod.com's Big 10`,
				triplets: `From JPBouvetMethod.com's Big 8`,
			},
			threes: `Groups of 3`,
			fives: `Groups of 5`,
		},
		complexity: {
			title: `Complexity`,
			custom: `Custom`,
			normal: `Normal`,
			moreRests: `More Space`,
		},
		controls: {
			bpm: `BPM`,
			count: `Count In`,
			ghost: `Ghost Notes`,
			metronome: `Metronome`,
		},
	},
	canvas: {
		shuffle: `Shuffle`,
		rhythmLock: `Rhythm Lock`,
		isolate: `Isolate`,
		unisolate: `Un-isolate`,
		grooveLock: `Groove Lock`,
		clear: `Clear Selection`,
	},
	credits: 'Created by Jamie Howard and JP Bouvet',
}
