import { createStore } from 'redux'
import rootReducer from './reducers'
import { composeWithDevTools } from 'redux-devtools-extension'

import { isLocalhost } from '../utils/functions'

// TODO: createStore deprecation?
let store = createStore(rootReducer)
if (isLocalhost()) {
	store = createStore(rootReducer, composeWithDevTools())
}

export default store
