import { _RB_DEBUG, _RB_DEBUG_CLEAR } from '../actionTypes'

// Debugger
export const _RbDebug = (content) => {
	return {
		type: _RB_DEBUG,
		payload: {
			content: JSON.stringify(content),
		},
	}
}

export const _RbDebugClear = () => {
	return {
		type: _RB_DEBUG_CLEAR,
	}
}
