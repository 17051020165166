import { _RB_DEBUG, _RB_DEBUG_CLEAR } from '../actionTypes'
import { initialDebugger } from '../initialStates/debugger'

export const debuggerReducer = (state = initialDebugger, action) => {
	switch (action.type) {
		case _RB_DEBUG: {
			const { content } = action.payload

			return {
				...state,
				content: [...state.content, content],
			}
		}
		case _RB_DEBUG_CLEAR: {
			return initialDebugger
		}

		default:
			return state
	}
}
