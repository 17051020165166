import { SET_IAP_MODAL_OPEN, SET_IS_PRO, SET_PRODUCT_ID } from '../actionTypes'
import { initialInAppPurchases } from '../initialStates/inAppPurchases'

export const inAppPurchasesReducer = (state = initialInAppPurchases, action) => {
	switch (action.type) {
		case SET_PRODUCT_ID: {
			const { id } = action.payload

			return {
				...state,
				productId: id,
			}
		}
		case SET_IS_PRO: {
			const { isPro } = action.payload

			return {
				...state,
				isPro,
			}
		}
		case SET_IAP_MODAL_OPEN: {
			const { isOpen } = action.payload

			return {
				...state,
				isModalOpen: isOpen,
			}
		}
		default:
			return state
	}
}
