import get from 'lodash/get'
import store from '../store'

import {
	SET_ACTX,
	SET_THEME,
	SET_VOLUME,
	SET_SHOW_FILTERS,
	SET_SHOW_SETTINGS,
	SET_ANALYTICS_PROMPT,
	SET_DONATION_PROMPT,
	SET_LOGIN_OVERLAY,
	TOGGLE_SHOW_SETTINGS,
	TOGGLE_SHOW_FILTERS,
	TOGGLE_ANALYTICS_PROMPT,
	TOGGLE_DONATION_PROMPT,
	SET_RENDERED_ARRAY,
	SET_ISOLATED_ARRAY,
	SET_SHOW_GROOVE_MODAL,
	SET_SHOW_SWING,
	SET_MODAL_HH_ARRAY,
	SET_SELECTED,
	SET_ISOLATED,
	SET_HIGHLIGHTED,
	SET_BAR_STYLE,
	SET_PLAY_ENABLED,
	SET_SAVEABLE,
	SET_LOADABLE,
	SET_SHOW_LOAD_MODAL,
	SET_SHOW_FEEDBACK_MODAL,
	SET_SHOW_HELPER_MODAL,
	SET_SHOW_ERROR,
	SET_ERROR_TEXT,
	SET_BARS,
	SET_TIME,
	SET_TIME_NAME,
	SET_PLAYBACK_TIME,
	SET_RHYTHM_NAME,
	SET_RANDOM,
	SET_PLAY_THIS,
	SET_BPM,
	SET_MODE,
	SET_PLAYING,
	SET_USE_METRONOME,
	SET_USE_COUNT_IN,
	SET_USE_GHOSTS,
	SET_PLAYBACK_AS,
	SET_GROOVE_CYMBAL_PATTERN,
	SET_GROOVE_CYMBAL_SOUND,
	SET_GROOVE_GHOSTS,
	SET_GROOVE_BACKBEAT,
	SET_SWING,
	SET_CUSTOM,
	SET_CUSTOM_ARRAY,
	SET_SPACE,
	SET_SELECTED_BARS,
	SET_LEVEL,
	SET_LEVELS_ARRAY,
	SET_SHOW_SOUNDBANK_MODAL,
	SET_UPDATE_PROMPT,
	SET_FEATURES_LIST,
	TOGGLE_TRADE_DIRECTION,
	SET_TRADE_DIRECTION,
	SET_GROOVE_MIX,
	TOGGLE_RHYTHM_LOCK,
	TOGGLE_GROOVE_LOCK,
	SET_GROOVE_LOCK,
	SET_RHYTHM_LOCK,
	RESET,
	SET_TIME_SIGNATURE,
	SET_TIME_SIGNATURE_TOP,
	SET_TIME_SIGNATURE_BOTTOM,
	SET_MOBILE_TIME_SIGNATURE_CONTROLS,
	MANUAL_TOGGLE_SCREEN_LOCK,
	SET_SCREEN_LOCK,
	SET_PLAYALONG_MODE,
	RESET_PLAYALONG_MODE,
	USER_PLAYALONG,
	HIDE_PLAYALONG_ACCURACY,
	SET_LANGUAGE,
	SET_RENDERING_MODE,
	SET_TRANSLATION_SETTINGS,
	SET_TRANSLATION_MODE,
	SET_APPLICATION_TEXT,
	SET_SHOW_CLICK_MODAL,
	SET_CLICK_MODAL_HAS_OPENED,
	SET_CLICK_RATE,
	SET_CLICK_OFFSET_AMOUNT,
	SET_CLICK_OFFSET_RATE,
	SWITCH_CLICK_OFFSET_RATE,
	SET_GAP_CLICK_MATCH_RHYTHM,
	SET_CLICK_MARK_ONE,
	SET_GAP_CLICK,
	SET_CLICK_RATE_AMOUNT,
	SET_CLICK_RATE_NOTE,
	SET_CLICK_RATE_CUSTOM,
	SET_EXACT_CLICK_RATE,
	SET_EXACT_CLICK_OFFSET,
	SET_EXACT_CLICK_GAP,
	RESET_CLICK,
	SWITCH_GAP_CLICK_OFF_FIRST,
	SET_MOBILE_CONTROLS_DOWN,
	SET_CLICK_VOLUME,
	RESET_PRESET,
	SET_PRESET,
	SET_PRESET_SHUFFLE_ALL,
	SET_PRESET_CONSTANTS,
	SET_PLATFORM,
	SET_SCREEN_LOCK_OBJECT,
	SET_MOBILE_TIME_SIGNATURE_SEEN,
	SET_ION_CLICK_CLICKS_PER_BEAT,
	SET_ION_CLICK_NUMBER_OF_BEATS,
	SET_ION_CLICK_BEAT_VOLUME,
	ION_TOGGLE_BEAT_STATE,
	SET_ION_CLICK_STEPWISE_MODE,
	SET_ION_CLICK_STEPWISE_BPM,
	SET_ION_CLICK_STEPWISE_BARS,
	RHYTHMIC_VOCABULARY_PERMUTATIONS_SET_GROUPING,
	RHYTHMIC_VOCABULARY_PERMUTATIONS_SET_START_POINTS,
	SET_SHOW_RV_PERMUTATIONS_MODAL,
	ION_RESET_BEATS,
	SET_ION_CLICK_RATE_DIVIDER,
	SET_ION_DISABLE_NUMBER_OF_BEATS,
	SET_ION_DISABLE_CLICKS_PER_BEAT,
	SET_ION_CLICK_STEPWISE_DIRECT,
	SET_ION_CLICK_BEAT_VOLUMES_DRIECT,
} from '../actionTypes'

import GrooveOptions from '../../utils/constants/groove-elements'
import ClickRateEnum, { ClickRateImages } from '../../utils/constants/enum/click'
import { ModeEnum } from '../../utils/constants/enum/mode'
import { SoundsEnum } from '../../utils/constants/enum/sounds'
import TimeEnum from '../../utils/constants/enum/time'
import { getValidBpm } from '../../utils/validation/bpm'
import { getValidBars } from '../../utils/validation/bars'
import { getValidEnumValue } from '../../utils/validation/enum'

import { initialPlayback } from '../initialStates/playback'

const highestNoteRate = (time) => {
	switch (time) {
		case TimeEnum.STRAIGHT:
		case TimeEnum.EIGHTH:
			return 16
		case TimeEnum.TRIPLETS:
			return 12
		default:
			return null
	}
}

const playbackTimeAdjustments = ({ selected }) => {
	const s = store.getState()
	switch (selected) {
		//Each time change - decide what to render, set the time name and update the level
		case TimeEnum.STRAIGHT:
			store.dispatch(setShowSwing(true))
			store.dispatch(setModalHHArray(GrooveOptions.options[0].hh))
			if (!get(s, `playback.click.offset.modified`)) {
				store.dispatch(setClickOffsetRate({ rate: ClickRateEnum.SIXTEENTH }))
				if (get(s, `playback.click.offset.amount`) > 4) {
					store.dispatch(setClickOffsetAmount({ amount: 4 }))
				}
			}
			break
		case TimeEnum.EIGHTH:
			store.dispatch(setShowSwing(true))
			store.dispatch(setModalHHArray(GrooveOptions.options[1].hh))
			if (!get(s, `playback.click.offset.modified`)) {
				store.dispatch(setClickOffsetRate({ rate: ClickRateEnum.SIXTEENTH }))
				if (get(s, `playback.click.offset.amount`) > 4) {
					store.dispatch(setClickOffsetAmount({ amount: 4 }))
				}
			}
			break
		case TimeEnum.TRIPLETS:
			store.dispatch(setShowSwing(false))
			store.dispatch(setModalHHArray(GrooveOptions.options[2].hh))
			if (!get(s, `playback.click.offset.modified`)) {
				store.dispatch(setClickOffsetRate({ rate: ClickRateEnum.TRIPLETS }))
				if (get(s, `playback.click.offset.amount`) > 3) {
					store.dispatch(setClickOffsetAmount({ amount: 3 }))
				}
			}
			break
		default:
			store.dispatch(setShowSwing(false))
			store.dispatch(setModalHHArray(GrooveOptions.options[3].hh))
			if (!get(s, `playback.click.offset.modified`)) {
				store.dispatch(setClickOffsetRate({ rate: ClickRateEnum.SIXTEENTH }))
				if (get(s, `playback.click.offset.amount`) > 4) {
					store.dispatch(setClickOffsetAmount({ amount: 4 }))
				}
			}
	}
}

//Audio
export const setAudioContext = (audioContext) => ({
	type: SET_ACTX,
	payload: {
		audioContext,
	},
})

export const setVolume = ({ payload, direct = false }) => {
	// TODO: this was used for overall volume, and not used anymore 06-03-22
	let volumeBoost = (payload / 50).toFixed(2)
	if (direct) {
		volumeBoost = payload
	}
	return {
		type: SET_VOLUME,
		payload: {
			volumeBoost,
		},
	}
}

//Rendering
export const setPlatform = (platform) => ({
	type: SET_PLATFORM,
	payload: {
		platform,
	},
})
export const setTheme = (theme) => ({
	type: SET_THEME,
	payload: {
		theme,
	},
})
export const setShowSettings = (showSettings) => ({
	type: SET_SHOW_SETTINGS,
	payload: {
		showSettings,
	},
})
export const setShowFilters = (showFilters) => ({
	type: SET_SHOW_FILTERS,
	payload: {
		showFilters,
	},
})
export const setAnalyticsPrompt = (analyticsPrompt) => ({
	type: SET_ANALYTICS_PROMPT,
	payload: {
		analyticsPrompt,
	},
})
export const setDonationPrompt = (donationPrompt) => ({
	type: SET_DONATION_PROMPT,
	payload: {
		donationPrompt,
	},
})
export const toggleShowSettings = () => ({
	type: TOGGLE_SHOW_SETTINGS,
})
export const toggleShowFilters = () => ({
	type: TOGGLE_SHOW_FILTERS,
})
export const toggleAnalyticsPrompt = () => ({
	type: TOGGLE_ANALYTICS_PROMPT,
})
export const toggleDonationsPrompt = () => ({
	type: TOGGLE_DONATION_PROMPT,
})
export const setRenderedArray = (renderedArray) => ({
	type: SET_RENDERED_ARRAY,
	payload: {
		renderedArray,
	},
})

export const setShowGrooveModal = (showGrooveModal) => ({
	type: SET_SHOW_GROOVE_MODAL,
	payload: {
		showGrooveModal,
	},
})
export const setShowSwing = (showSwing) => ({
	type: SET_SHOW_SWING,
	payload: {
		showSwing,
	},
})
export const setModalHHArray = (modalHHArray) => ({
	type: SET_MODAL_HH_ARRAY,
	payload: {
		modalHHArray,
	},
})
export const setSelected = (selected) => ({
	type: SET_SELECTED,
	payload: {
		selected,
	},
})

export const setHighlighted = (highlighted) => ({
	type: SET_HIGHLIGHTED,
	payload: {
		highlighted,
	},
})
export const setBarSettings = (barSettings) => ({
	type: SET_BAR_STYLE,
	payload: {
		barSettings,
	},
})

export const setSaveable = (saveable) => ({
	type: SET_SAVEABLE,
	payload: {
		saveable,
	},
})
export const setLoadable = (loadable) => ({
	type: SET_LOADABLE,
	payload: {
		loadable,
	},
})
export const setShowLoadModal = (showLoadModal) => ({
	type: SET_SHOW_LOAD_MODAL,
	payload: {
		showLoadModal,
	},
})
export const setShowFeedbackModal = (showFeedbackModal) => ({
	type: SET_SHOW_FEEDBACK_MODAL,
	payload: {
		showFeedbackModal,
	},
})

export const setShowHelperModal = ({ show, title, text, footer }) => ({
	type: SET_SHOW_HELPER_MODAL,
	payload: {
		show,
		title,
		text,
		footer,
	},
})
export const setShowError = (showError) => ({
	type: SET_SHOW_ERROR,
	payload: {
		showError,
	},
})
export const setErrorText = (errorText) => ({
	type: SET_ERROR_TEXT,
	payload: {
		errorText,
	},
})
export const setShowSoundbankModal = (showSoundbankModal) => ({
	type: SET_SHOW_SOUNDBANK_MODAL,
	payload: {
		showSoundbankModal,
	},
})
export const setUpdatePrompt = (updatePrompt) => ({
	type: SET_UPDATE_PROMPT,
	payload: {
		updatePrompt,
	},
})
export const setFeaturesList = (featuresList) => ({
	type: SET_FEATURES_LIST,
	payload: {
		featuresList,
	},
})
export const setMobileTimeSignatureControls = (mobileTimeSignatureControls) => ({
	type: SET_MOBILE_TIME_SIGNATURE_CONTROLS,
	payload: {
		mobileTimeSignatureControls,
	},
})
export const setMobileTimeSignatureSeen = (seen) => ({
	type: SET_MOBILE_TIME_SIGNATURE_SEEN,
	payload: {
		seen,
	},
})
export const setMobileControlsDown = ({ down, manual = false, manualReset = false }) => ({
	type: SET_MOBILE_CONTROLS_DOWN,
	payload: {
		down,
		manual,
		manualReset,
	},
})
export const toggleScreenLock = () => ({
	type: MANUAL_TOGGLE_SCREEN_LOCK,
})

export const setScreenLock = (screenLock) => ({
	type: SET_SCREEN_LOCK,
	payload: {
		screenLock,
	},
})

export const setScreenLockObject = (lockObject) => ({
	type: SET_SCREEN_LOCK_OBJECT,
	payload: {
		lockObject,
	},
})

export const setLoginOverlay = (loginOverlay) => ({
	type: SET_LOGIN_OVERLAY,
	payload: {
		loginOverlay,
	},
})

export const setLanguage = (language) => ({
	type: SET_LANGUAGE,
	payload: {
		language,
	},
})

export const setShowTranslationSettings = (showSettings) => ({
	type: SET_TRANSLATION_SETTINGS,
	payload: {
		showSettings,
	},
})

export const setTranslationMode = (mode) => ({
	type: SET_TRANSLATION_MODE,
	payload: {
		mode,
	},
})

export const setApplicationText = ({ filters, credits, canvas }) => ({
	type: SET_APPLICATION_TEXT,
	payload: {
		filters,
		canvas,
		credits,
	},
})

export const setRenderingMode = (renderingMode) => ({
	type: SET_RENDERING_MODE,
	payload: {
		renderingMode,
	},
})

export const setShowClickModal = (showClickModal) => ({
	type: SET_SHOW_CLICK_MODAL,
	payload: {
		showClickModal,
	},
})

export const setShowRVPermutationsModal = (show) => ({
	type: SET_SHOW_RV_PERMUTATIONS_MODAL,
	payload: {
		show,
	},
})

/**
 * Manually set the click modal `hasOpened` to true. Used when responding to
 * click query params
 */
export const setClickModalHasOpened = () => ({
	type: SET_CLICK_MODAL_HAS_OPENED,
})

//Playback
export const setBars = (bars) => ({
	type: SET_BARS,
	payload: {
		bars: getValidBars(bars),
	},
})
export const setTime = (options) => {
	const validOptions = getValidEnumValue(TimeEnum, options, initialPlayback.time.options)

	return {
		type: SET_TIME,
		payload: {
			time: {
				options: validOptions,
				highestNoteRate: highestNoteRate(validOptions),
			},
		},
	}
}

export const setPlaybackTime = (options) => {
	const validOptions = getValidEnumValue(TimeEnum, options, initialPlayback.time.options)

	playbackTimeAdjustments({ selected: validOptions })

	return {
		type: SET_PLAYBACK_TIME,
		payload: {
			playbackTime: {
				options: validOptions,
				highestNoteRate: highestNoteRate(validOptions),
			},
		},
	}
}

export const setTimeName = (timeName) => ({
	type: SET_TIME_NAME,
	payload: {
		timeName,
	},
})

export const setRhythmName = ({ name, preset = false }) => ({
	type: SET_RHYTHM_NAME,
	payload: {
		name,
		preset,
	},
})
export const setRandom = (random) => ({
	type: SET_RANDOM,
	payload: {
		random,
	},
})

export const setPlayThis = (playThis) => ({
	type: SET_PLAY_THIS,
	payload: {
		playThis,
	},
})

export const setBpm = (bpm) => ({
	type: SET_BPM,
	payload: {
		bpm: getValidBpm(bpm),
	},
})

export const setMode = (mode) => ({
	type: SET_MODE,
	payload: {
		mode: getValidEnumValue(ModeEnum, mode, initialPlayback.mode),
	},
})

export const setPlaybackAs = (playbackAs) => ({
	type: SET_PLAYBACK_AS,
	payload: {
		playbackAs: getValidEnumValue(SoundsEnum, playbackAs, initialPlayback.playbackAs),
	},
})

export const setPlaying = (playing) => ({
	type: SET_PLAYING,
	payload: {
		playing,
	},
})
export const setUseMetronome = (useMetronome) => ({
	type: SET_USE_METRONOME,
	payload: {
		useMetronome,
	},
})
export const setUseCountIn = (useCountIn) => ({
	type: SET_USE_COUNT_IN,
	payload: {
		useCountIn,
	},
})

export const setUseGhosts = (useGhosts) => ({
	type: SET_USE_GHOSTS,
	payload: {
		useGhosts,
	},
})

export const setGrooveCymbalPattern = (pattern) => ({
	type: SET_GROOVE_CYMBAL_PATTERN,
	payload: {
		pattern,
	},
})
export const setGrooveCymbalSound = (sound) => ({
	type: SET_GROOVE_CYMBAL_SOUND,
	payload: {
		sound,
	},
})
export const setGrooveGhosts = (ghosts) => ({
	type: SET_GROOVE_GHOSTS,
	payload: {
		ghosts,
	},
})
export const setGrooveBackbeat = (backbeat) => ({
	type: SET_GROOVE_BACKBEAT,
	payload: {
		backbeat,
	},
})
export const setGrooveMix = (mix) => ({
	type: SET_GROOVE_MIX,
	payload: {
		mix,
	},
})
export const setSwing = (swing) => ({
	type: SET_SWING,
	payload: {
		swing,
	},
})
export const setCustom = (custom) => ({
	type: SET_CUSTOM,
	payload: {
		custom,
	},
})
export const setCustomArray = (customArray) => ({
	type: SET_CUSTOM_ARRAY,
	payload: {
		customArray,
	},
})
export const setSpace = (space) => ({
	type: SET_SPACE,
	payload: {
		space,
	},
})
export const setSelectedBars = (selectedBars) => ({
	type: SET_SELECTED_BARS,
	payload: {
		selectedBars,
	},
})
export const setIsolatedArray = (isolatedArray) => ({
	type: SET_ISOLATED_ARRAY,
	payload: {
		isolatedArray,
	},
})
export const setIsolated = (isolated) => ({
	type: SET_ISOLATED,
	payload: {
		isolated,
	},
})
export const setPlayEnabled = (playEnabled) => ({
	type: SET_PLAY_ENABLED,
	payload: {
		playEnabled,
	},
})
export const setLevel = (level) => ({
	type: SET_LEVEL,
	payload: {
		level,
	},
})
export const setLevelsArray = (levelsArray) => ({
	type: SET_LEVELS_ARRAY,
	payload: {
		levelsArray,
	},
})
export const toggleTradeDirection = () => ({
	type: TOGGLE_TRADE_DIRECTION,
})
export const setTradeDirection = (tradeDirection) => ({
	type: SET_TRADE_DIRECTION,
	payload: {
		tradeDirection,
	},
})
export const setRhythmLock = (rhythmLock) => ({
	type: SET_RHYTHM_LOCK,
	payload: {
		rhythmLock,
	},
})
export const toggleRhythmLock = () => ({
	type: TOGGLE_RHYTHM_LOCK,
})
export const setGrooveLock = (grooveLock) => ({
	type: SET_GROOVE_LOCK,
	payload: {
		grooveLock,
	},
})
export const toggleGrooveLock = () => ({
	type: TOGGLE_GROOVE_LOCK,
})
export const setTimeSignature = (payload) => {
	return {
		type: SET_TIME_SIGNATURE,
		payload: {
			timeSignature: {
				top: parseInt(payload.split(':')[0]),
				bottom: parseInt(payload.split(':')[1]),
			},
		},
	}
}
export const setTimeSignatureTop = (top) => ({
	type: SET_TIME_SIGNATURE_TOP,
	payload: {
		top,
	},
})
export const setTimeSignatureBottom = (bottom) => ({
	type: SET_TIME_SIGNATURE_BOTTOM,
	payload: {
		bottom,
	},
})
export const setPlayalong = (on) => ({
	type: SET_PLAYALONG_MODE,
	payload: {
		on,
	},
})
export const hidePlayalongAccuracy = (loopCount) => ({
	type: HIDE_PLAYALONG_ACCURACY,
	payload: {
		loopCount,
	},
})

export const userPlayalong = (stats) => ({
	type: USER_PLAYALONG,
	payload: {
		stats,
	},
})

export const resetPlayalongMode = () => ({
	type: RESET_PLAYALONG_MODE,
})
export const resetClick = () => ({
	type: RESET_CLICK,
})
export const setClickVolume = (volume) => ({
	type: SET_CLICK_VOLUME,
	payload: {
		volume,
	},
})

/**
 * Set the entire click rate object - used in query params
 * @param {Object} clickRateObj the click rate object
 */
export const setExactClickRate = ({ clickRateObj }) => ({
	type: SET_EXACT_CLICK_RATE,
	payload: {
		clickRateObj,
	},
})

export const setClickrate = ({ imageKey }) => ({
	type: SET_CLICK_RATE,
	payload: {
		selectedImage: Number(imageKey),
		newRate: get(ClickRateImages, `${imageKey}`),
		custom: !get(ClickRateImages, `${imageKey}.auto`, false),
	},
})
export const setClickRateCustom = ({ bool }) => ({
	type: SET_CLICK_RATE_CUSTOM,
	payload: {
		bool,
	},
})
export const setClickRateAmount = ({ value }) => ({
	type: SET_CLICK_RATE_AMOUNT,
	payload: {
		value,
	},
})
export const setClickRateNote = ({ value }) => ({
	type: SET_CLICK_RATE_NOTE,
	payload: {
		value,
	},
})

/**
 * Set the entire click offset object - used in query params
 * @param {Object} clickOffsetObj the click rate object
 */
export const setExactClickOffset = ({ clickOffsetObj }) => ({
	type: SET_EXACT_CLICK_OFFSET,
	payload: {
		clickOffsetObj,
	},
})

export const setClickOffsetAmount = ({ amount, manual = false }) => ({
	type: SET_CLICK_OFFSET_AMOUNT,
	payload: {
		amount,
		manual,
	},
})
export const setClickOffsetRate = ({ rate }) => ({
	type: SET_CLICK_OFFSET_RATE,
	payload: {
		rate,
	},
})
export const switchClickOffsetRate = () => ({
	type: SWITCH_CLICK_OFFSET_RATE,
})
export const setClickMatchRhythm = (bool) => ({
	type: SET_GAP_CLICK_MATCH_RHYTHM,
	payload: {
		bool,
	},
})
export const setClickMarkOne = (bool) => ({
	type: SET_CLICK_MARK_ONE,
	payload: {
		bool,
	},
})

/**
 * Set the entire click gap object - used in query params
 * @param {Object} clickGapObj the click rate object
 */
export const setExactClickGap = ({ clickGapObj }) => ({
	type: SET_EXACT_CLICK_GAP,
	payload: {
		clickGapObj,
	},
})

export const setGapClickOn = ({ amount, firstChange = false }) => {
	const turnOff = amount === 0 && (get(store.getState(), 'playback.click.gap.off') === 0 || get(store.getState(), 'playback.click.gap.on') === false)
	if (turnOff) {
		return {
			type: SET_GAP_CLICK,
			payload: {
				on: false,
				off: false,
				touched: false,
			},
		}
	}
	if (!firstChange) {
		return {
			type: SET_GAP_CLICK,
			payload: {
				on: amount,
			},
		}
	}
	return {
		type: SET_GAP_CLICK,
		payload: {
			on: amount,
			off: amount,
			touched: true,
		},
	}
}

export const setGapClickOff = ({ amount, firstChange = false }) => {
	const turnOff = amount === 0 && (get(store.getState(), 'playback.click.gap.on') === 0 || get(store.getState(), 'playback.click.gap.on') === false)
	if (turnOff) {
		return {
			type: SET_GAP_CLICK,
			payload: {
				on: false,
				off: false,
				touched: false,
			},
		}
	}
	if (!firstChange) {
		return {
			type: SET_GAP_CLICK,
			payload: {
				off: amount,
			},
		}
	}
	return {
		type: SET_GAP_CLICK,
		payload: {
			on: amount,
			off: amount,
			touched: true,
		},
	}
}
export const switchGapClickOffFirst = () => {
	return {
		type: SWITCH_GAP_CLICK_OFF_FIRST,
	}
}

export const ionMetronomeSetNumberOfBeats = (numberOfBeats) => ({
	type: SET_ION_CLICK_NUMBER_OF_BEATS,
	payload: {
		numberOfBeats,
	},
})

export const ionMetronomeSetClicksPerBeat = (clicksPerBeat) => ({
	type: SET_ION_CLICK_CLICKS_PER_BEAT,
	payload: {
		clicksPerBeat,
	},
})
export const ionMetronomeSetBeatVolumesDirect = (payload) => ({
	type: SET_ION_CLICK_BEAT_VOLUMES_DRIECT,
	payload: {
		payload,
	},
})
export const ionMetronomeSetBeatVolume = (index, volume) => ({
	type: SET_ION_CLICK_BEAT_VOLUME,
	payload: {
		index,
		volume,
	},
})
export const ionMetronomeResetBeats = () => ({
	type: ION_RESET_BEATS,
})
export const ionMetronomeToggleBeatState = (index) => ({
	type: ION_TOGGLE_BEAT_STATE,
	payload: {
		index,
	},
})
export const ionMetronomeStepwiseDirect = (payload) => ({
	type: SET_ION_CLICK_STEPWISE_DIRECT,
	payload: {
		payload,
	},
})
export const ionMetronomeStepwiseMode = (mode) => ({
	type: SET_ION_CLICK_STEPWISE_MODE,
	payload: {
		mode,
	},
})
export const ionMetronomeStepwiseBpm = (bpm) => ({
	type: SET_ION_CLICK_STEPWISE_BPM,
	payload: {
		bpm,
	},
})
export const ionMetronomeStepwiseBars = (bars) => ({
	type: SET_ION_CLICK_STEPWISE_BARS,
	payload: {
		bars,
	},
})

export const ionMetronomeRateDivider = (value) => ({
	type: SET_ION_CLICK_RATE_DIVIDER,
	payload: {
		value,
	},
})

export const ionSetDisableNumberOfBeats = (value) => ({
	type: SET_ION_DISABLE_NUMBER_OF_BEATS,
	payload: {
		value,
	},
})

export const ionSetDisableClickPerBeat = (value) => ({
	type: SET_ION_DISABLE_CLICKS_PER_BEAT,
	payload: {
		value,
	},
})

export const resetPreset = () => {
	return {
		type: RESET_PRESET,
	}
}

export const setPreset = ({ on }) => {
	return {
		type: SET_PRESET,
		payload: {
			on,
		},
	}
}

export const setPresetShuffleAll = ({ on, params }) => {
	return {
		type: SET_PRESET_SHUFFLE_ALL,
		payload: {
			on,
			params,
		},
	}
}

export const setPresetConstants = ({ constants }) => {
	return {
		type: SET_PRESET_CONSTANTS,
		payload: {
			constants,
		},
	}
}

// Rhythmic vocabulary permutations
export const rhythmicVocabPermutationsSetGrouping = (groupingIndex) => {
	return {
		type: RHYTHMIC_VOCABULARY_PERMUTATIONS_SET_GROUPING,
		payload: {
			groupingIndex,
		},
	}
}

export const rhythmicVocabPermutationsSetStartPoints = (startingPoints) => {
	return {
		type: RHYTHMIC_VOCABULARY_PERMUTATIONS_SET_START_POINTS,
		payload: {
			startingPoints,
		},
	}
}

//Reset
export const reset = () => ({
	type: RESET,
})
