export const SoundsEnum = {
	SNARE: 0,
	KICK: 1,
	GROOVE: 2,
}

export const getSoundName = (s) => {
	switch (s) {
		case SoundsEnum.SNARE:
			return `Snare`
		case SoundsEnum.KICK:
			return `Kick`
		case SoundsEnum.GROOVE:
			return `Groove`
		default:
			return ``
	}
}

export const CymbalEnum = {
	HH: 0,
	RIDE: 1,
}
export const getCymbalSoundName = (s) => {
	switch (s) {
		case CymbalEnum.HH:
			return `Hi-hat`
		case CymbalEnum.RIDE:
			return `Ride`
		default:
			return ``
	}
}

export const GhostsEnum = {
	FILL: 0,
	MATCH: 1,
}
