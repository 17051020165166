export const ClickRateEnum = {
	QUARTER: 0,
	EIGHTH: 1,
	SIXTEENTH: 2,
	TRIPLETS: 3,
}

export const ClickRateImages = {
	1: {
		auto: true,
		amount: 1,
		time: ClickRateEnum.QUARTER,
	},
	2: {
		amount: 1,
		time: ClickRateEnum.QUARTER,
	},
	3: {
		amount: 1,
		time: ClickRateEnum.EIGHTH,
	},
	4: {
		amount: 1,
		time: ClickRateEnum.SIXTEENTH,
	},
	5: {
		amount: 3,
		time: ClickRateEnum.EIGHTH,
	},
	6: {
		amount: 3,
		time: ClickRateEnum.SIXTEENTH,
	},
	7: {
		amount: 5,
		time: ClickRateEnum.SIXTEENTH,
	},
	8: {
		amount: 1,
		time: ClickRateEnum.TRIPLETS,
	},
	9: {
		amount: 2,
		time: ClickRateEnum.TRIPLETS,
	},
	10: {
		custom: true,
	},
}

export default ClickRateEnum
