import { timeSignatureLimits } from '../functions'

export const isValidTimeSignature = (top: number, bottom: number) => {
	if (![4, 8, 16].includes(bottom)) {
		return false
	}

	// TODO sort out the return format of timeSignatureLimits
	const limits: any = timeSignatureLimits(bottom)

	return top >= limits.lower && top <= limits.upper
}
