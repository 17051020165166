import get from 'lodash/get'
import set from 'lodash/set'
import startsWith from 'lodash/startsWith'

import { Capacitor } from '@capacitor/core'
import { Clipboard } from '@capacitor/clipboard'

import store from '../redux/store'
import { setErrorText, setShowError } from '../redux/actions'

import { isMobileOnly } from 'react-device-detect'

const percentageValueText = (value) => `${value}%`

const clickOffsetValueText = ({ straight = true, value }) => {
	const straightKeys = [`1`, `e`, `&`, `a`, `2`]
	const swungKeys = [`1`, `&`, `a`, `2`]

	switch (straight) {
		case true:
			return straightKeys[value]
		default:
			return swungKeys[value]
	}
}

const getMobileHeightParams = () => {
	const firstVisit = !JSON.parse(localStorage.getItem('mobileTimeSignatureSeen'))
	const ret = {}
	if (window.innerHeight < 550) {
		//Small mobile device
		set(ret, 'rest', firstVisit ? '2.75' : '3')
		set(ret, 'up', '2.4')
	} else {
		set(ret, 'rest', firstVisit ? '3.7' : '4.6')
		set(ret, 'up', '3.35')
	}
	return ret
}

/**
 * Triggers a short animation on mobile devices, showing how to access the time signature
 */
const mobileTimeSignatureAnimation = () => {
	if (!isMobileOnly) {
		return
	}
	const heightParams = getMobileHeightParams()
	document.documentElement.style.setProperty('--mobile-controls-animation-delay', `0.75s`)
	document.documentElement.style.setProperty('--mobile-controls-height-divider', get(heightParams, `up`))
	setTimeout(() => {
		document.documentElement.style.setProperty('--mobile-controls-animation-delay', `0.39s`)
		document.documentElement.style.setProperty('--mobile-controls-height-divider', get(heightParams, `rest`))
	}, 800)
}

const timeSignatureLimits = (bottom) => {
	switch (bottom) {
		case 16:
			return {
				upper: 19,
				lower: 5,
			}
		case 8:
			return {
				upper: 13,
				lower: 4,
			}
		case 4:
			return {
				upper: 7,
				lower: 2,
			}
		default:
	}
}

const arrayEquals = (a, b) => Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index])

const arrayAverage = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length

const countOccurrences = (arr, val) => arr.reduce((a, v) => (v === val ? a + 1 : a), 0)

const beta = () => process.env.REACT_APP_ALLOW_BETA_FEATURES === `true`

const isValidUrl = (string) => {
	try {
		new URL(string)
		return true
	} catch (error) {
		return false
	}
}

const prod = ({ isApp = false }) => {
	if (beta()) {
		return false
	}

	if (isApp) {
		return true
	}

	return ['www.rhythmbot.app', 'rhythmbot.app'].includes(window.location.hostname)
}

const dev = () => beta() || ['localhost', 'dev.rhythmbot.app'].includes(window.location.hostname) || startsWith(window.location.hostname, '192.168')

const isLocalhost = () => ['localhost'].includes(window.location.hostname) || startsWith(window.location.hostname, '192.168')

const desktop = (device) => device === 'desktop'

const isIonic = () => process.env.REACT_APP_IONIC_UI === `true`

const errorOccurred = (text) => {
	console.error(text)
	store.dispatch(setErrorText(text))
	store.dispatch(setShowError(true))
}
window.errorOccurred = errorOccurred

const copyToClipboard = async ({ text, isApp = false }) => {
	const copyToClipboardFallback = (text) => {
		const elem = document.createElement('textarea')
		elem.value = text
		document.body.appendChild(elem)
		elem.select()
		document.execCommand('copy')
		document.body.removeChild(elem)
		console.info('Copied to clipboard - fallback method')
	}
	if (isApp) {
		await Clipboard.write({
			string: text,
		})
		return
	}
	if (get(navigator, `clipboard.writeText`, false)) {
		navigator.clipboard.writeText(text).then(
			() => console.info('Copied to clipboard'),
			() => {
				console.error(`Navigator clipboard copy failed... using fallback`)
				copyToClipboardFallback(text)
			}
		)
		return
	}
	copyToClipboardFallback(text)
}

/**
 * @param {String} queryParams query params to append to the URL
 */
const copyShareableURL = ({ queryParams = ``, isApp = false }) => {
	const path = get(window, `location.pathname`, ``)
	let origin = get(window, `location.origin`, ``)
	let shareableUrl = ``

	if (isApp || isIonic()) {
		origin = `https://${beta() ? `dev.` : ``}rhythmbot.app`
		shareableUrl = `${origin}${queryParams}`

		return copyToClipboard({ text: shareableUrl, isApp })
	}
	shareableUrl = `${origin}${path}${queryParams}`
	copyToClipboard({ text: shareableUrl, isApp })
}

const openDonations = () => {
	let language = window.navigator.userLanguage || window.navigator.language
	window.open(language.toLowerCase() === 'en-gb' ? process.env.REACT_APP_PAYPAL + 'GBP' : process.env.REACT_APP_PAYPAL + 'USD', '_blank')
}

const wakeLockAvailable = () => 'wakeLock' in navigator || Capacitor.isNativePlatform()

const requestWakeLock = async () => {
	try {
		let wakeLock = await navigator.wakeLock.request()
		wakeLock.addEventListener('release', () => {
			console.info('Screen Wake Lock released:', wakeLock.released)
		})
		console.info('Screen Wake Lock released:', wakeLock.released)
		return wakeLock
	} catch (err) {
		console.error(`${err.name}, ${err.message}`)
	}
}

const lowestCommonMultiple = (x, y) => {
	if (typeof x !== 'number' || typeof y !== 'number') {
		return false
	}

	return !x || !y ? 0 : Math.abs((x * y) / greatestCommonDivider(x, y))
}

const greatestCommonDivider = (x, y) => {
	x = Math.abs(x)
	y = Math.abs(y)
	while (y) {
		var t = y
		y = x % y
		x = t
	}
	return x
}

const makeID = (length) => {
	var result = ''
	var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
	var charactersLength = characters.length
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength))
	}
	return result
}

const resizeArray = (arr, targetLength, defaultValue) => {
	if (targetLength > arr.length) {
		return arr.concat(Array(targetLength - arr.length).fill(defaultValue))
	}
	if (targetLength < arr.length) {
		return arr.slice(0, targetLength)
	}
	return arr
}

const getKeyByValue = (object, value) => Object.keys(object).find((key) => object[key] === value)

const toTitleCase = (str) =>
	str.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
		return match.toUpperCase()
	})
/**
 * @param {Number} length
 * @param {Number} min
 * @param {Number} max
 * @returns {Array}
 */
const generateUniqueRandomArray = (length, min, max) => {
	if (length > max - min + 1) {
		errorOccurred('Range too small for the number of unique values requested')
		return
	}

	let arr = new Set()
	while (arr.size < length) {
		arr.add(Math.floor(Math.random() * (max - min + 1) + min))
	}
	return Array.from(arr)
}

/**
 * Get a random number between min and max, excluding the exclude value
 * @param {Number} min
 * @param {Number} max
 * @param {Number} exclude
 * @returns {Number}
 */
const getRandomInRangeExclude = (min, max, exclude) => {
	let random = Math.floor(Math.random() * (max - min + 1)) + min
	while (random === exclude) {
		random = Math.floor(Math.random() * (max - min + 1)) + min
	}

	return random
}

export {
	percentageValueText,
	arrayEquals,
	errorOccurred,
	copyToClipboard,
	prod,
	dev,
	countOccurrences,
	openDonations,
	arrayAverage,
	wakeLockAvailable,
	requestWakeLock,
	desktop,
	lowestCommonMultiple,
	timeSignatureLimits,
	isLocalhost,
	copyShareableURL,
	getMobileHeightParams,
	clickOffsetValueText,
	beta,
	mobileTimeSignatureAnimation,
	isIonic,
	makeID,
	resizeArray,
	getKeyByValue,
	toTitleCase,
	generateUniqueRandomArray,
	getRandomInRangeExclude,
	isValidUrl,
}
