import get from 'lodash/get'
import isNil from 'lodash/isNil'

import {
	SET_BARS,
	SET_TIME,
	SET_PLAYBACK_TIME,
	SET_RANDOM,
	SET_PLAY_THIS,
	SET_BPM,
	SET_MODE,
	SET_PLAYING,
	SET_USE_METRONOME,
	SET_USE_COUNT_IN,
	SET_USE_GHOSTS,
	SET_PLAYBACK_AS,
	SET_GROOVE_CYMBAL_PATTERN,
	SET_GROOVE_CYMBAL_SOUND,
	SET_GROOVE_GHOSTS,
	SET_GROOVE_BACKBEAT,
	SET_SWING,
	SET_CUSTOM,
	SET_CUSTOM_ARRAY,
	SET_SELECTED_BARS,
	SET_ISOLATED,
	SET_PLAY_ENABLED,
	SET_ISOLATED_ARRAY,
	SET_LEVELS_ARRAY,
	SET_LEVEL,
	TOGGLE_TRADE_DIRECTION,
	SET_TRADE_DIRECTION,
	SET_GROOVE_MIX,
	SET_RHYTHM_LOCK,
	TOGGLE_RHYTHM_LOCK,
	SET_GROOVE_LOCK,
	TOGGLE_GROOVE_LOCK,
	SET_SPACE,
	SET_TIME_SIGNATURE,
	SET_TIME_SIGNATURE_BOTTOM,
	SET_TIME_SIGNATURE_TOP,
	SET_PLAYALONG_MODE,
	RESET_PLAYALONG_MODE,
	HIDE_PLAYALONG_ACCURACY,
	SET_CLICK_RATE,
	SET_CLICK_OFFSET_AMOUNT,
	SET_CLICK_OFFSET_RATE,
	SWITCH_CLICK_OFFSET_RATE,
	SET_GAP_CLICK_MATCH_RHYTHM,
	SET_CLICK_MARK_ONE,
	SET_GAP_CLICK,
	SET_CLICK_RATE_AMOUNT,
	SET_CLICK_RATE_CUSTOM,
	SET_CLICK_RATE_NOTE,
	RESET_CLICK,
	SWITCH_GAP_CLICK_OFF_FIRST,
	SET_CLICK_VOLUME,
	RESET_PRESET,
	SET_PRESET,
	SET_PRESET_SHUFFLE_ALL,
	SET_PRESET_CONSTANTS,
	SET_EXACT_CLICK_RATE,
	SET_EXACT_CLICK_OFFSET,
	SET_EXACT_CLICK_GAP,
	SET_ION_CLICK_NUMBER_OF_BEATS,
	SET_ION_CLICK_CLICKS_PER_BEAT,
	SET_ION_CLICK_BEAT_VOLUME,
	ION_TOGGLE_BEAT_STATE,
	SET_ION_CLICK_STEPWISE_MODE,
	SET_ION_CLICK_STEPWISE_BARS,
	SET_ION_CLICK_STEPWISE_BPM,
	RHYTHMIC_VOCABULARY_PERMUTATIONS_SET_GROUPING,
	RHYTHMIC_VOCABULARY_PERMUTATIONS_SET_START_POINTS,
	ION_RESET_BEATS,
	SET_ION_CLICK_RATE_DIVIDER,
	SET_ION_DISABLE_NUMBER_OF_BEATS,
	SET_ION_DISABLE_CLICKS_PER_BEAT,
	SET_ION_CLICK_STEPWISE_DIRECT,
	SET_ION_CLICK_BEAT_VOLUMES_DRIECT,
	USER_PLAYALONG,
} from '../actionTypes'

import { getInitialBeatVolumes, initialPlayback } from '../initialStates/playback'
import { resizeArray } from '../../utils/functions'
import ClickRateEnum from '../../utils/constants/enum/click'
import ModeEnum from '../../utils/constants/enum/mode'
import { BeatStateEnum } from '../../utils/constants/enum/ionic/metronome'
import { isValidTimeSignature } from '../../utils/validation/timeSignature'

const playalongAccuracy = ({ hits, misses, perRhythm }) => {
	// TODO logic is not quite right
	// let adjustment = 0
	let accuracy = Math.min(Math.max(0, (hits / perRhythm) * 100), 100)
	// 7 hits 3 misses
	// 7 hits = 90%
	// Accuracy = 90 * (Hits/(hits + misses))
	// = 90 * (7/10)
	// = 63
	if (misses > 0) {
		accuracy = Math.round(accuracy * (hits / (hits + misses)))
		// adjustment = ((100 / perRhythm) * misses) / 2
	}

	return accuracy
}

const addToPlayAlongHistory = (history, accuracy, hits, misses, loopCount, currentRhythm, currentBars) => {
	const newHistory = [...history]

	if (!newHistory[loopCount]) {
		const hitsCopy = [...hits]
		const missesCopy = [...misses]

		// TODO why so much copying
		let loopCountCopy = loopCount
		let accuracyCopy = accuracy

		newHistory.push({
			accuracy: accuracyCopy,
			hits: hitsCopy.filter((hit) => hit?.loopCount === loopCountCopy),
			misses: missesCopy.filter((miss) => miss?.loopCount === loopCountCopy),
			rhythm: currentRhythm,
			bars: currentBars,
		})
	}

	return newHistory
}

// const timeSignatureTimeAdjustments = (timeSignature, time) => {
// 	return
// 	if (get(timeSignature, 'bottom') !== 8) {
// 		return { time }
// 	}
// 	const top = get(timeSignature, 'top')
// 	if (top === 6 || top === 12) {
// 		return {
// 			time: {
// 				...time,
// 				options: 64,
// 				previousOptions: time.options,
// 			},
// 		}
// 	}
// 	console.log('previous Options', time.previousOptions)
// 	return {
// 		time: {
// 			...time,
// 			options: time.previousOptions,
// 			previousOptions: null,
// 		},
// 	}
// }

export default function playback(state = initialPlayback, action) {
	switch (action.type) {
		case SET_BARS: {
			const { bars } = action.payload
			return {
				...state,
				bars: bars,
			}
		}
		case SET_TIME: {
			const { time } = action.payload
			// const { timeSignature } = state
			// if (timeSignature.bottom === 8 && (timeSignature.top === 6 || timeSignature.top === 12)) {
			// 	time.options = 64
			// }
			return {
				...state,
				time: time,
			}
		}
		case SET_PLAYBACK_TIME: {
			const { playbackTime } = action.payload
			return {
				...state,
				playbackTime: playbackTime,
			}
		}
		case SET_RANDOM: {
			const { random } = action.payload
			return {
				...state,
				random: random,
			}
		}
		case SET_PLAY_THIS: {
			const { playThis } = action.payload
			return {
				...state,
				playThis: playThis,
			}
		}
		case SET_BPM: {
			const { bpm } = action.payload

			return {
				...state,
				bpm: bpm,
			}
		}
		case SET_MODE: {
			const { mode } = action.payload

			return {
				...state,
				mode,
				click: {
					...state.click,
					on: [ModeEnum.CLICK, ModeEnum.CLICKREADING].includes(mode) ? true : state.click.on,
				},
			}
		}
		case SET_PLAYING: {
			const { playing } = action.payload

			return {
				...state,
				playing: playing,
			}
		}
		case SET_USE_METRONOME: {
			const { useMetronome } = action.payload

			return {
				...state,
				click: {
					...state.click,
					on: useMetronome,
				},
			}
		}
		case SET_USE_COUNT_IN: {
			const { useCountIn } = action.payload

			return {
				...state,
				click: {
					...state.click,
					countIn: useCountIn,
				},
			}
		}
		case SET_USE_GHOSTS: {
			const { useGhosts } = action.payload

			return {
				...state,
				useGhosts: useGhosts,
			}
		}
		case SET_PLAYBACK_AS: {
			const { playbackAs } = action.payload

			return {
				...state,
				playbackAs: playbackAs,
			}
		}
		case SET_GROOVE_CYMBAL_PATTERN: {
			const { pattern } = action.payload

			return {
				...state,
				groove: {
					...state.groove,
					cymbal: {
						...state.groove.cymbal,
						pattern,
					},
				},
			}
		}
		case SET_GROOVE_CYMBAL_SOUND: {
			const { sound } = action.payload

			return {
				...state,
				groove: {
					...state.groove,
					cymbal: {
						...state.groove.cymbal,
						sound,
					},
				},
			}
		}
		case SET_GROOVE_GHOSTS: {
			const { ghosts } = action.payload

			return {
				...state,
				groove: {
					...state.groove,
					ghosts,
				},
			}
		}
		case SET_GROOVE_BACKBEAT: {
			const { backbeat } = action.payload

			return {
				...state,
				groove: {
					...state.groove,
					backbeat,
				},
			}
		}
		case SET_GROOVE_MIX: {
			const { mix } = action.payload

			return {
				...state,
				groove: {
					...state.groove,
					mix,
				},
			}
		}
		case SET_GROOVE_LOCK: {
			const { lock } = action.payload

			return {
				...state,
				groove: {
					...state.groove,
					lock,
				},
			}
		}
		case TOGGLE_GROOVE_LOCK: {
			return {
				...state,
				groove: {
					...state.groove,
					lock: !state.groove.lock,
				},
			}
		}
		case SET_SWING: {
			const { swing } = action.payload

			return {
				...state,
				swing: swing,
			}
		}
		case SET_CUSTOM: {
			const { custom } = action.payload

			return {
				...state,
				custom: custom,
			}
		}
		case SET_CUSTOM_ARRAY: {
			const { customArray } = action.payload

			return {
				...state,
				customArray: customArray,
			}
		}
		case SET_SPACE: {
			const { space } = action.payload

			return {
				...state,
				space: space,
			}
		}
		case SET_SELECTED_BARS: {
			const { selectedBars } = action.payload

			return {
				...state,
				selectedBars: selectedBars,
			}
		}
		case SET_PLAY_ENABLED: {
			const { playEnabled } = action.payload

			return {
				...state,
				playEnabled: playEnabled,
			}
		}
		case SET_ISOLATED: {
			const { isolated } = action.payload

			return {
				...state,
				isolated: isolated,
			}
		}
		case SET_ISOLATED_ARRAY: {
			const { isolatedArray } = action.payload

			return {
				...state,
				isolatedArray: isolatedArray,
			}
		}
		case SET_LEVELS_ARRAY: {
			const { levelsArray } = action.payload

			return {
				...state,
				levelsArray: levelsArray,
			}
		}
		case SET_LEVEL: {
			const { level } = action.payload

			return {
				...state,
				level: level,
			}
		}
		case TOGGLE_TRADE_DIRECTION: {
			return {
				...state,
				tradeDirection: !state.tradeDirection,
			}
		}
		case SET_TRADE_DIRECTION: {
			const { tradeDirection } = action.payload

			return {
				...state,
				tradeDirection: tradeDirection,
			}
		}
		case SET_RHYTHM_LOCK: {
			const { rhythmLock } = action.payload

			return {
				...state,
				rhythmLock: rhythmLock,
			}
		}
		case TOGGLE_RHYTHM_LOCK: {
			return {
				...state,
				rhythmLock: !state.rhythmLock,
			}
		}
		case SET_TIME_SIGNATURE: {
			const {
				timeSignature: { top, bottom },
			} = action.payload

			if (!isValidTimeSignature(top, bottom)) {
				return state
			}

			// const { time } = timeSignatureTimeAdjustments(newTimeSignature, state.time)
			return {
				...state,
				// time,
				timeSignature: {
					...state.timeSignature,
					top,
					bottom,
				},
			}
		}
		case SET_TIME_SIGNATURE_TOP: {
			const { top } = action.payload

			// const { time } = timeSignatureTimeAdjustments(newTimeSignature, state.time)
			return {
				...state,
				// time,
				timeSignature: {
					...state.timeSignature,
					top,
				},
			}
		}
		case SET_TIME_SIGNATURE_BOTTOM: {
			const { bottom } = action.payload
			// const { time } = timeSignatureTimeAdjustments(newTimeSignature, state.time)
			return {
				...state,
				// time,
				timeSignature: {
					...state.timeSignature,
					bottom,
				},
			}
		}
		case SET_PLAYALONG_MODE: {
			const { on } = action.payload

			let countIn = state?.click?.countIn ?? true
			if (on) {
				countIn = true
			}

			return {
				...state,
				swing: 0,
				playalong: {
					...state.playalong,
					on,
				},
				click: {
					...state.click,
					countIn,
				},
			}
		}
		case HIDE_PLAYALONG_ACCURACY: {
			const { loopCount } = action.payload

			// Only hide the accuracy if there are no hits or misses for this loop
			const shouldHide =
				state?.playalong?.user?.hits?.filter((hit) => hit?.loopCount === loopCount).length === 0 &&
				state?.playalong?.user?.misses?.filter((miss) => miss?.loopCount === loopCount).length === 0

			if (!shouldHide) {
				return state
			}

			return {
				...state,
				playalong: {
					...state.playalong,
					hideAccuracy: true,
				},
			}
		}
		case USER_PLAYALONG: {
			const stats = action?.payload?.stats ?? {}

			const hits = state?.playalong?.user?.hits ?? []
			const misses = state?.playalong?.user?.misses ?? []

			const { isSixteenth, currentNoOfBars, isMixed, currentRhythm: currentRhythmState } = stats

			let { position, loopCount } = stats
			let currentRhythmNotation
			if (isMixed) {
				if (isSixteenth) {
					currentRhythmNotation = currentRhythmState?.straight ?? []
				} else {
					currentRhythmNotation = currentRhythmState?.triplets ?? []
				}
			} else {
				currentRhythmNotation = [...(currentRhythmState || [])]
			}

			if (position < 0) {
				// If position is less than 0, we can't do anything
				return state
			}

			if (position >= currentRhythmNotation.length) {
				// If position is longer than the rhythm length, we need to add
				// (value % rhythmLength) as the position and add 1 to the
				// loopCount
				// the interaction actually happened in the next loop
				loopCount += Math.floor(position / currentRhythmNotation.length)
				position = position % currentRhythmNotation.length
			}

			const doubledInteraction =
				hits.filter((hit) => hit?.loopCount === loopCount && hit?.position === position && hit?.isSixteenth === isSixteenth).length > 0 ||
				misses.filter((miss) => miss?.loopCount === loopCount && miss?.position === position && miss?.isSixteenth === isSixteenth).length > 0

			if (doubledInteraction) {
				// If the interaction is doubled, we can't do anything
				return state
			}

			const firstHitOfLoop =
				(hits.length === 0 && misses.length === 0) ||
				(hits.filter((hit) => hit?.loopCount === loopCount).length === 0 && misses.filter((miss) => miss?.loopCount === loopCount).length === 0)

			let updateCurrentRhythm
			if (firstHitOfLoop) {
				// Only update the rhythm if it's the first interaction of this
				// loop
				// This makes sure we are always updating the history with the
				// right rhythm and calculating the accuracy correctly
				updateCurrentRhythm = true
			} else {
				updateCurrentRhythm = false
			}

			const newLoop =
				loopCount > 0 &&
				(hits.length > 0 || misses.length > 0) &&
				(loopCount !== (misses[misses.length - 1]?.loopCount ?? loopCount) || loopCount !== (hits[hits.length - 1]?.loopCount ?? loopCount))

			// TODO in mixed time we will currently take the rhythm state in the
			// form {straight: [], triplets: []}. Fix when supporting play along
			// summary for mixed time
			const updatedRhythmState = updateCurrentRhythm ? currentRhythmState : state?.playalong?.user?.rhythm ?? []

			let history = state?.playalong?.user?.history ?? []
			if (newLoop) {
				// It being a new loop indicates that we need to add the stats
				// for the most recent loop to the history
				history = addToPlayAlongHistory(history, state.playalong.user.accuracy, hits, misses, loopCount - 1, updatedRhythmState, currentNoOfBars)
			}

			const isHit = currentRhythmNotation[position] === 1
			if (isHit) {
				hits.push({
					position,
					loopCount,
					isSixteenth,
				})
			} else {
				misses.push({
					position,
					loopCount,
					isSixteenth,
				})
			}

			const allNotes = isMixed ? [...(updatedRhythmState?.straight ?? []), ...(updatedRhythmState?.triplets ?? [])] : updatedRhythmState
			const hitsPerRhythm = allNotes?.filter((hit) => hit === 1).length
			const accuracy = playalongAccuracy({
				hits: hits.filter((hit) => hit?.loopCount === loopCount).length,
				misses: misses.filter((miss) => miss?.loopCount === loopCount).length,
				perRhythm: hitsPerRhythm,
			})

			return {
				...state,
				playalong: {
					...state.playalong,
					hideAccuracy: false,
					user: {
						...state.playalong.user,
						hits,
						misses,
						accuracy,
						history,
						rhythm: updatedRhythmState,
						bars: currentNoOfBars,
						isSixteenth,
					},
				},
			}
		}
		case RESET_PLAYALONG_MODE: {
			return {
				...state,
				playalong: {
					...state.playalong,
					user: {
						hits: [],
						misses: [],
						accuracy: 0,
						history: [],
						rhythm: [],
						bars: 1,
						isSixteenth: true,
					},
				},
			}
		}
		case RESET_CLICK: {
			return {
				...state,
				click: {
					...state.click,
					volume: initialPlayback.click.volume,
					offset: {
						...initialPlayback.click.offset,
					},
					gap: {
						...initialPlayback.click.gap,
					},
					rate: {
						...initialPlayback.click.rate,
					},
					ionic: {
						...initialPlayback.click.ionic,
					},
				},
			}
		}
		case SET_CLICK_VOLUME: {
			const { volume } = action.payload

			return {
				...state,
				click: {
					...state.click,
					volume,
				},
			}
		}
		case SET_EXACT_CLICK_RATE: {
			const { clickRateObj } = action.payload
			const convertedObject = {
				custom: Boolean(get(clickRateObj, `custom`, 0)),
				selectedImage: get(clickRateObj, `selectedImage`, 0),
				amount: get(clickRateObj, `amount`, 0),
				time: get(clickRateObj, `time`, 0),
				markOne: Boolean(get(clickRateObj, `markOne`, 0)),
			}

			return {
				...state,
				click: {
					...state.click,
					offset: {
						...state.click.offset,
					},
					gap: {
						...state.click.gap,
					},
					rate: {
						...convertedObject,
					},
				},
			}
		}
		case SET_CLICK_RATE: {
			const { selectedImage, newRate, custom } = action.payload
			return {
				...state,
				click: {
					...state.click,
					offset: {
						...state.click.offset,
					},
					gap: {
						...state.click.gap,
					},
					rate: {
						...state.click.rate,
						custom,
						selectedImage,
						amount: get(newRate, `amount`, false) || state.click.rate.amount,
						time: get(newRate, `time`, false) === false ? state.click.rate.time : get(newRate, `time`, false),
					},
				},
			}
		}
		case SET_CLICK_RATE_CUSTOM: {
			const { bool } = action.payload
			return {
				...state,
				click: {
					...state.click,
					offset: {
						...state.click.offset,
					},
					gap: {
						...state.click.gap,
					},
					rate: {
						...state.click.rate,
						custom: bool,
						selectedImage: !bool ? 1 : 10,
					},
				},
			}
		}
		case SET_CLICK_RATE_AMOUNT: {
			const { value } = action.payload
			return {
				...state,
				click: {
					...state.click,
					offset: {
						...state.click.offset,
					},
					gap: {
						...state.click.gap,
					},
					rate: {
						...state.click.rate,
						amount: value,
					},
				},
			}
		}
		case SET_CLICK_RATE_NOTE: {
			const { value } = action.payload
			return {
				...state,
				click: {
					...state.click,
					offset: {
						...state.click.offset,
					},
					gap: {
						...state.click.gap,
					},
					rate: {
						...state.click.rate,
						time: value,
					},
				},
			}
		}
		case SET_EXACT_CLICK_OFFSET: {
			const { clickOffsetObj } = action.payload
			const convertedObject = {
				amount: get(clickOffsetObj, `amount`, 0),
				rate: get(clickOffsetObj, `rate`, 0),
				modified: Boolean(get(clickOffsetObj, `modified`, 0)),
			}
			return {
				...state,
				click: {
					...state.click,
					rate: {
						...state.click.rate,
					},
					gap: {
						...state.click.gap,
					},
					offset: {
						...convertedObject,
					},
				},
			}
		}
		case SET_CLICK_OFFSET_AMOUNT: {
			const { amount, manual } = action.payload
			let a = amount
			const rate = get(state, `click.offset.rate`)
			switch (rate) {
				// Deal with maximum offsets, if 2 is selected at any point move
				// it back to the latest sensible offset
				case ClickRateEnum.SIXTEENTH:
					if (a > 3) {
						a = 3
					}
					break
				default:
					if (a > 2) {
						a = 2
					}
			}
			return {
				...state,
				click: {
					...state.click,
					rate: {
						...state.click.rate,
					},
					gap: {
						...state.click.gap,
					},
					offset: {
						...state.click.offset,
						amount: a,
						modified: manual || state.click.offset.modified,
					},
				},
			}
		}
		case SET_CLICK_OFFSET_RATE: {
			const { rate } = action.payload
			const stateAmount = get(state, `click.offset.amount`)
			let amount = false
			switch (rate) {
				case ClickRateEnum.SIXTEENTH:
					if (stateAmount > 4) {
						amount = 4
					}
					break
				case ClickRateEnum.TRIPLETS:
					if (stateAmount > 3) {
						amount = 3
					}
					break
				default:
			}
			return {
				...state,
				click: {
					...state.click,
					rate: {
						...state.click.rate,
					},
					gap: {
						...state.click.gap,
					},
					offset: {
						...state.click.offset,
						rate,
						amount: amount || stateAmount,
					},
				},
			}
		}
		case SWITCH_CLICK_OFFSET_RATE: {
			const stateRate = get(state, `click.offset.rate`)
			const stateAmount = get(state, `click.offset.amount`)
			let amount = false
			let to
			switch (stateRate) {
				case ClickRateEnum.SIXTEENTH:
					to = ClickRateEnum.TRIPLETS
					if (stateAmount > 3) {
						amount = 3
					}
					break
				default:
					to = ClickRateEnum.SIXTEENTH
					if (stateAmount > 4) {
						amount = 4
					}
			}
			return {
				...state,
				click: {
					...state.click,
					rate: {
						...state.click.rate,
					},
					gap: {
						...state.click.gap,
					},
					offset: {
						...state.click.offset,
						rate: to,
						modified: true, // Manual modification done through this action
						amount: amount || stateAmount,
					},
				},
			}
		}
		case SET_GAP_CLICK_MATCH_RHYTHM: {
			const { bool } = action.payload
			return {
				...state,
				click: {
					...state.click,
					rate: {
						...state.click.rate,
					},
					offset: {
						...state.click.offset,
					},
					gap: {
						...state.click.gap,
						matchRhythmLength: bool,
					},
				},
			}
		}
		case SET_CLICK_MARK_ONE: {
			const { bool } = action.payload
			return {
				...state,
				click: {
					...state.click,
					offset: {
						...state.click.offset,
					},
					gap: {
						...state.click.gap,
					},
					rate: {
						...state.click.rate,
						markOne: bool,
					},
				},
			}
		}
		case SET_EXACT_CLICK_GAP: {
			const { clickGapObj } = action.payload
			const convertedObject = {
				on: get(clickGapObj, `on`, false),
				off: get(clickGapObj, `off`, false),
				matchRhythmLength: Boolean(get(clickGapObj, `matchRhythmLength`, 0)),
				startOff: Boolean(get(clickGapObj, `startOff`, 0)),
				controlsTouched: Boolean(get(clickGapObj, `controlsTouched`, 0)),
			}
			return {
				...state,
				click: {
					...state.click,
					rate: {
						...state.click.rate,
					},
					offset: {
						...state.click.offset,
					},
					gap: {
						...convertedObject,
					},
				},
			}
		}
		case SET_GAP_CLICK: {
			const { on, off, touched } = action.payload
			return {
				...state,
				click: {
					...state.click,
					offset: {
						...state.click.offset,
					},
					rate: {
						...state.click.rate,
					},
					gap: {
						...state.click.gap,
						on: isNil(on) ? state.click.gap.on : on,
						off: isNil(off) ? state.click.gap.off : off,
						controlsTouched: isNil(touched) ? state.click.gap.controlsTouched : touched,
					},
				},
			}
		}
		case SWITCH_GAP_CLICK_OFF_FIRST: {
			return {
				...state,
				click: {
					...state.click,
					offset: {
						...state.click.offset,
					},
					rate: {
						...state.click.rate,
					},
					gap: {
						...state.click.gap,
						startOff: !state.click.gap.startOff,
					},
				},
			}
		}
		case SET_ION_CLICK_NUMBER_OF_BEATS: {
			const { numberOfBeats } = action.payload

			if (state.click.ionic.disabled.numberOfBeats) {
				return state
			}

			return {
				...state,
				click: {
					...state.click,
					ionic: {
						...state.click.ionic,
						numberOfBeats,
						beatVolumes: resizeArray(state.click.ionic.beatVolumes, numberOfBeats, { volume: 1.0, state: BeatStateEnum.ON }),
					},
				},
			}
		}
		case SET_ION_CLICK_CLICKS_PER_BEAT: {
			const { clicksPerBeat } = action.payload

			if (state.click.ionic.disabled.clicksPerBeat) {
				return state
			}

			return {
				...state,
				click: {
					...state.click,
					ionic: {
						...state.click.ionic,
						clicksPerBeat,
					},
				},
			}
		}
		case SET_ION_CLICK_BEAT_VOLUMES_DRIECT: {
			const { payload } = action.payload

			return {
				...state,
				click: {
					...state.click,
					ionic: {
						...state.click.ionic,
						beatVolumes: payload,
					},
				},
			}
		}
		case SET_ION_CLICK_BEAT_VOLUME: {
			const { index, volume } = action.payload
			return {
				...state,
				click: {
					...state.click,
					ionic: {
						...state.click.ionic,
						beatVolumes: state.click.ionic.beatVolumes.map((beatVolume, i) => {
							if (i !== index) {
								return beatVolume
							}

							return { volume, state: beatVolume.state === BeatStateEnum.OFF ? BeatStateEnum.ON : beatVolume.state }
						}),
					},
				},
			}
		}
		case ION_RESET_BEATS: {
			return {
				...state,
				click: {
					...state.click,
					ionic: {
						...state.click.ionic,
						beatVolumes: getInitialBeatVolumes(state.click.ionic.numberOfBeats),
					},
				},
			}
		}
		case ION_TOGGLE_BEAT_STATE: {
			const { index } = action.payload

			return {
				...state,
				click: {
					...state.click,
					ionic: {
						...state.click.ionic,
						beatVolumes: state.click.ionic.beatVolumes.map((beatVolume, i) => {
							if (i !== index) {
								return beatVolume
							}

							const nextState = (beatVolume.state + 1) % Object.keys(BeatStateEnum).length

							return { ...beatVolume, state: nextState }
						}),
					},
				},
			}
		}

		case SET_ION_CLICK_STEPWISE_DIRECT: {
			const { payload } = action.payload
			return {
				...state,
				click: {
					...state.click,
					ionic: {
						...state.click.ionic,
						stepwise: payload,
					},
				},
			}
		}

		case SET_ION_CLICK_STEPWISE_MODE: {
			const { mode } = action.payload
			return {
				...state,
				click: {
					...state.click,
					ionic: {
						...state.click.ionic,
						stepwise: {
							...state.click.ionic.stepwise,
							mode,
						},
					},
				},
			}
		}
		case SET_ION_CLICK_STEPWISE_BARS: {
			const { bars } = action.payload
			return {
				...state,
				click: {
					...state.click,
					ionic: {
						...state.click.ionic,
						stepwise: {
							...state.click.ionic.stepwise,
							bars,
						},
					},
				},
			}
		}
		case SET_ION_CLICK_STEPWISE_BPM: {
			const { bpm } = action.payload
			return {
				...state,
				click: {
					...state.click,
					ionic: {
						...state.click.ionic,
						stepwise: {
							...state.click.ionic.stepwise,
							bpm,
						},
					},
				},
			}
		}
		case SET_ION_CLICK_RATE_DIVIDER: {
			const { value } = action.payload
			return {
				...state,
				click: {
					...state.click,
					ionic: {
						...state.click.ionic,
						rateDivider: value,
					},
				},
			}
		}
		case SET_ION_DISABLE_NUMBER_OF_BEATS: {
			const { value } = action.payload

			return {
				...state,
				click: {
					...state.click,
					ionic: {
						...state.click.ionic,
						disabled: {
							...state.click.ionic.disabled,
							numberOfBeats: value,
						},
					},
				},
			}
		}
		case SET_ION_DISABLE_CLICKS_PER_BEAT: {
			const { value } = action.payload

			return {
				...state,
				click: {
					...state.click,
					ionic: {
						...state.click.ionic,
						disabled: {
							...state.click.ionic.disabled,
							clicksPerBeat: value,
						},
					},
				},
			}
		}
		case RESET_PRESET: {
			return {
				...state,
				preset: {
					...initialPlayback.preset,
					constants: state.preset.constants,
				},
			}
		}
		case SET_PRESET: {
			const { on } = action.payload
			return {
				...state,
				preset: {
					...state.preset,
					on,
				},
			}
		}
		case SET_PRESET_SHUFFLE_ALL: {
			const { on, params } = action.payload
			return {
				...state,
				preset: {
					...state.preset,
					shuffleAll: {
						on,
						params,
					},
				},
			}
		}
		case SET_PRESET_CONSTANTS: {
			const { constants } = action.payload
			return {
				...state,
				preset: {
					...state.preset,
					constants,
				},
			}
		}

		// Rhythmic Vocabulary Permutations
		case RHYTHMIC_VOCABULARY_PERMUTATIONS_SET_GROUPING: {
			const { groupingIndex } = action.payload
			return {
				...state,
				rhythmicVocabularyPermutations: {
					...state.rhythmicVocabularyPermutations,
					groupingIndex,
				},
			}
		}

		case RHYTHMIC_VOCABULARY_PERMUTATIONS_SET_START_POINTS: {
			const { startingPoints } = action.payload
			return {
				...state,
				rhythmicVocabularyPermutations: {
					...state.rhythmicVocabularyPermutations,
					startingPoints,
				},
			}
		}

		default:
			return state
	}
}
