import { SET_IAP_MODAL_OPEN, SET_IS_PRO, SET_PRODUCT_ID } from '../actionTypes'

export const setProductId = (id) => {
	return {
		type: SET_PRODUCT_ID,
		payload: {
			id,
		},
	}
}

export const setIsPro = (payload) => ({
	type: SET_IS_PRO,
	payload: {
		isPro: payload,
	},
})

export const setIsIAPModalOpen = (payload) => ({
	type: SET_IAP_MODAL_OPEN,
	payload: {
		isOpen: payload,
	},
})
