import { SET_ACTX, SET_VOLUME } from '../actionTypes'

import { initialAudio } from '../initialStates/audio'

export default function audio(state = initialAudio, action) {
	switch (action.type) {
		case SET_ACTX: {
			const { audioContext } = action.payload
			return {
				...state,
				audioContext,
			}
		}

		case SET_VOLUME: {
			const { volumeBoost } = action.payload
			return {
				...state,
				volumeBoost,
			}
		}

		default:
			return state
	}
}
