import get from 'lodash/get'
import { combineReducers } from 'redux'
import audio from './audio'
import playback from './playback'
import rendering from './rendering'
import ionic from './ionic'
import { inAppPurchasesReducer } from './inAppPurchases'
import { debuggerReducer } from './debugger'

import { initialRendering } from '../initialStates/rendering'

const appReducer = combineReducers({
	audio: audio,
	playback: playback,
	rendering: rendering,
	ionic: ionic,
	debugger: debuggerReducer,
	inAppPurchases: inAppPurchasesReducer,
})

const rootReducer = (state, action) => {
	if (action.type === 'RESET') {
		const { audio, rendering, inAppPurchases } = state
		state = {
			audio: { ...audio, volumeBoost: 1.0 },
			rendering: {
				...initialRendering,
				platform: get(rendering, 'platform'),
				theme: get(rendering, 'theme'),
				showSettings: get(rendering, 'showSettings'),
				showFilters: get(rendering, 'showFilters'),
				text: { ...get(rendering, 'text') },
			},
			inAppPurchases,
		}
	}

	return appReducer(state, action)
}

export default rootReducer
